import React, { ReactNode, memo } from 'react';
import cx from 'classnames';

import styles from './Chip.module.scss';

export type ChipProps = {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  size?: 'small' | 'large';
  wrap?: boolean;
  type?:
    | 'primary'
    | 'warning'
    | 'tertiary'
    | 'critical'
    | 'orange'
    | 'interactive';
};

export const Chip: React.FC<ChipProps> = memo(
  ({
    className,
    children,
    type = 'tertiary',
    size = 'large',
    onClick,
    wrap = false,
  }) => {
    return (
      <div
        className={cx(
          styles.container,
          className,
          [styles[type]],
          [styles[size]],
          { [styles.wrap]: wrap }
        )}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
);

Chip.displayName = 'Chip';
