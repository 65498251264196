import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import ResponsiveObserve, {
  Breakpoint,
  ScreenMap,
  responsiveArray,
} from 'lib/utils/responsiveObserve';
import RowContext from './RowContext';

import styles from './Row.module.scss';

export type Gutter = number | Partial<Record<Breakpoint, number>>;

export interface RowProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** Vertical alignment */
  align?: 'start' | 'center' | 'end' | 'stretch';
  /** Horizontal alignment */
  justify?: 'start' | 'center' | 'end' | 'space-around' | 'space-between';
  /** Auto wrap line */
  wrap?: boolean;
  /** Spacing between grids, could be a number or an object like { xs: 8, sm: 16, md: 24}. Or you can use array to make horizontal and vertical spacing work at the same time [horizontal, vertical] */
  gutter?: Gutter | [Gutter, Gutter];
  /** children */
  children?: React.ReactNode;
  /** isFullWidthRow */
  isFullWidthRow?: boolean;
  columnGap?: number;
  rowGap?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Row: React.FC<RowProps> = React.forwardRef(
  (
    {
      className = '', // custom class name
      style, // custom style
      align = 'start',
      justify = 'start',
      wrap = true,
      gutter = 0,
      isFullWidthRow = false,
      columnGap,
      rowGap,
      onClick,
      children,
    }: RowProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [screens, setScreens] = useState<ScreenMap>({
      xs: true,
      sm: true,
      md: true,
      lg: true,
      xl: true,
      xxl: true,
    });

    const gutterRef = React.useRef<Gutter | [Gutter, Gutter]>(gutter);

    useEffect(() => {
      const token = ResponsiveObserve.subscribe((screen) => {
        const currentGutter = gutterRef.current || 0;
        if (
          (!Array.isArray(currentGutter) &&
            typeof currentGutter === 'object') ||
          (Array.isArray(currentGutter) &&
            (typeof currentGutter[0] === 'object' ||
              typeof currentGutter[1] === 'object'))
        ) {
          setScreens(screen);
        }
      });

      return () => ResponsiveObserve.unsubscribe(token);
    }, []);

    const getGutter = (): [number, number] => {
      const results: [number, number] = [0, 0];
      const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, 0];
      normalizedGutter.forEach((g, index) => {
        if (typeof g === 'object') {
          for (let i = 0; i < responsiveArray.length; i++) {
            const breakpoint: Breakpoint = responsiveArray[i];
            if (screens[breakpoint] && g[breakpoint] !== undefined) {
              results[index] = g[breakpoint] as number;
              break;
            }
          }
        } else {
          results[index] = g || 0;
        }
      });
      return results;
    };

    const gutters = getGutter();

    const contentClassNames = cn(
      {
        [styles.Row]: true,
        [`${styles[`RowAlign${align.split('-').map(capitalize).join('')}`]}`]:
          align,
        [`${
          styles[`RowJustify${justify.split('-').map(capitalize).join('')}`]
        }`]: justify,
        [styles.RowWrap]: wrap,
        [styles.RowFullWidth]: isFullWidthRow,
      },
      className
    );

    // Add gutter related style
    const rowStyle: React.CSSProperties = {};
    const horizontalGutter = gutters[0] > 0 ? gutters[0] / -2 : undefined;
    const verticalGutter = gutters[1] > 0 ? gutters[1] / -2 : undefined;

    rowStyle.marginLeft = horizontalGutter;
    rowStyle.marginRight = horizontalGutter;

    rowStyle.marginTop = verticalGutter;
    rowStyle.marginBottom = verticalGutter;

    return (
      <RowContext.Provider value={{ gutter: gutters }}>
        <div
          className={contentClassNames}
          style={{
            columnGap: columnGap ? `${columnGap}px` : undefined,
            rowGap: rowGap ? `${rowGap}px` : undefined,
            ...rowStyle,
            ...style,
          }}
          data-testid="Row"
          ref={ref}
          onClick={onClick}
        >
          {children}
        </div>
      </RowContext.Provider>
    );
  }
);
