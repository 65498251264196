export type Breakpoint =
  | 'xxl'
  | 'xl'
  | 'lg'
  | 'md'
  | 'sm'
  | 'xs'
  | 'sm_desktop_down'
  | 'xs_up';
export type BreakpointMap = Record<Breakpoint, string>;
export type ScreenMap = Partial<Record<Breakpoint, boolean>>;

export const responsiveArray: Breakpoint[] = [
  'xxl',
  'xl',
  'lg',
  'md',
  'sm',
  'xs',
  'sm_desktop_down',
  'xs_up',
];

export const responsiveMap: BreakpointMap = {
  xs: '(max-width: 414px)',
  sm: '(max-width: 767px)',
  sm_desktop_down: '(max-width: 1080px)',
  md: '(max-width: 1279px)',
  lg: '(min-width: 1280px)',
  xl: '(min-width: 1440px)',
  xxl: '(min-width: 1800px)',
  xs_up: '(min-width: 481px)',
};

type SubscribeFunc = (screens: ScreenMap) => void;
const subscribers = new Map<number, SubscribeFunc>();
let subUid = 0;
let screens: ScreenMap = {};

const responsiveObserve = {
  matchHandlers: {} as {
    [prop: string]: {
      mql: MediaQueryList;
      listener: (_: MediaQueryListEvent) => void | null;
    };
  },
  register(): void {
    Object.keys(responsiveMap).forEach((screen) => {
      const matchMediaQuery = responsiveMap[screen as keyof BreakpointMap];
      const listener = ({ matches }: { matches: boolean }) => {
        this.dispatch({
          ...screens,
          [screen]: matches,
        });
      };
      const mql = window.matchMedia(matchMediaQuery);
      if (mql.addEventListener) {
        mql.addEventListener('change', listener);
      } else {
        mql.addListener(listener);
      }
      listener(mql);
    });
  },
  unregister(): void {
    Object.keys(responsiveMap).forEach((screen) => {
      const matchMediaQuery = responsiveMap[screen as keyof BreakpointMap];
      const handler = this.matchHandlers[matchMediaQuery];
      if (handler) {
        if (handler.mql.removeEventListener) {
          handler.mql.removeEventListener('change', handler.listener);
        } else {
          handler.mql.removeListener(handler.listener);
        }
      }
    });
  },
  subscribe(callback: SubscribeFunc): number {
    if (!subscribers.size) this.register();
    subUid += 1;
    subscribers.set(subUid, callback);
    callback(screens);
    return subUid;
  },
  unsubscribe(id: number): void {
    subscribers.delete(id);
    if (!subscribers.size) this.unregister();
  },
  dispatch(map: ScreenMap): boolean {
    screens = map;
    subscribers.forEach((cb) => cb(screens));
    return subscribers.size >= 1;
  },
};

export default responsiveObserve;
