import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import cn from 'classnames';

import { useRouter } from 'next/router';
import Toaster from 'components/Toaster';
import { ToasterV2 } from 'components/ComponentV2';
import { useNotification } from 'hooks/useNotification';
import {
  Notification,
  NotificationTypeEnum,
  VerticalPositionEnum,
  HorizontalPositionEnum,
} from 'lib/models/notification';

import { PAGE_ROUTES } from 'lib/page-routes';
import styles from './NotificationToaster.module.scss';

export interface NotificationToasterProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
}

export const NotificationToaster: React.FC<NotificationToasterProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
  }: NotificationToasterProps) => {
    const contentClassNames = cn(styles.container, className);
    const router = useRouter();
    const notificationInstance = useNotification();
    const [notification, setNotification] = useState<Notification | null>(null);
    const timerRef = useRef<number | null>(null);

    const clearTimer = useCallback(() => {
      if (timerRef.current !== null) {
        window.clearTimeout(timerRef.current);
      }
    }, []);

    const handleClose = () => {
      setNotification(null);
      clearTimer();
    };

    useEffect(() => {
      function handleRouteChange(route: string) {
        if (
          route === PAGE_ROUTES.DASHBOARD ||
          route === PAGE_ROUTES.CAMPAIGNS
        ) {
          handleClose();
        }
      }
      if (notification?.type === NotificationTypeEnum.PendingCard)
        router.events.on('routeChangeComplete', handleRouteChange);
    }, [router, notification, handleClose]);

    useEffect(() => {
      const subscription = notificationInstance.notifications$.subscribe(
        (notification) => {
          if (notification.type === NotificationTypeEnum.Clear) {
            setNotification(null);
            clearTimer();
            return;
          }

          setNotification(notification);
          clearTimer();

          const timeout = notification.timeout || 5000;
          timerRef.current = window.setTimeout(() => {
            setNotification(null);
          }, timeout);
        }
      );

      return () => {
        subscription.unsubscribe();
      };
    }, [clearTimer]);

    if (!notification) return <></>;

    const getNotification = (notificationType: NotificationTypeEnum) => {
      switch (notificationType) {
        case NotificationTypeEnum.Custom:
          return (
            <div
              className={cn(
                styles.custom,
                ToasterVerticalPosition[
                  notification.position?.vertical || VerticalPositionEnum.TOP
                ],
                ToasterHorizontalPosition[
                  notification.position?.horizontal ||
                    HorizontalPositionEnum.CENTER
                ]
              )}
            >
              {notification.renderContent &&
                notification.renderContent(handleClose)}
            </div>
          );
        case NotificationTypeEnum.PendingCard:
          return (
            <div className={cn(styles.pendingCard)}>
              {notification.renderContent &&
                notification.renderContent(handleClose)}
            </div>
          );
        case NotificationTypeEnum.Acknowledge:
          return (
            <div className={className} style={style}>
              <ToasterV2
                type={notification.type}
                message={notification.message}
                loading={notification.loading}
                icon={notification.icon}
                handleClose={notification.showClose ? handleClose : undefined}
                variant={notification.variant}
                borderRounded={notification.borderRounded}
              />
            </div>
          );
        case NotificationTypeEnum.Failure:
          return (
            <div className={className} style={style}>
              <ToasterV2
                type={notification.type}
                message={notification.message}
                loading={notification.loading}
                handleClose={notification.showClose ? handleClose : undefined}
                variant={notification.variant}
                borderRounded={notification.borderRounded}
              />
            </div>
          );
        case NotificationTypeEnum.Information:
          return (
            <div className={className} style={style}>
              <ToasterV2
                type={notification.type}
                title={notification.title}
                message={notification.message}
                loading={notification.loading}
                icon={notification.icon}
                borderRounded={notification.borderRounded}
                variant={notification.variant}
                handleClose={notification.showClose ? handleClose : undefined}
              />
            </div>
          );

        default:
          return (
            <div
              className={contentClassNames}
              style={style}
              data-testid="NotificationToaster"
            >
              <Toaster
                title={notification.title}
                message={notification.message}
                type={notification.type}
                onClose={handleClose}
              />
            </div>
          );
      }
    };

    return getNotification(notification.type);
  }
);

const ToasterVerticalPosition = {
  [VerticalPositionEnum.TOP]: styles.top,
  [VerticalPositionEnum.BOTTOM]: styles.bottom,
};

const ToasterHorizontalPosition = {
  [HorizontalPositionEnum.LEFT]: styles.left,
  [HorizontalPositionEnum.CENTER]: styles.center,
  [HorizontalPositionEnum.RIGHT]: styles.right,
};

NotificationToaster.displayName = 'NotificationToaster';
