import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { pageview, GTM_KEY } from 'lib/gtm';

export const GTM: React.FC = () => {
  const router = useRouter();
  const gtmScriptRef = useRef(false);

  useEffect(() => {
    if (!GTM_KEY) return;

    gtmScriptRef.current = true;
    pageview();

    const handleRouteChange = () => {
      pageview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, GTM_KEY]);

  if (
    !GTM_KEY ||
    // @ts-ignore
    (typeof window !== 'undefined' && window.gaGlobal) ||
    gtmScriptRef.current
  )
    return null;

  return (
    <>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_KEY}');
          `,
        }}
      />
    </>
  );
};

GTM.displayName = 'GTM';
