import { FLOW } from 'hooks/useApp';
import { MatchesEmailFrequency } from 'hooks/useCandidateMatches/useCandidateMatchesInternal';
import {
  AccomplishmentsResponseResponse,
  CandidateResume,
  EducationHistoriesResponse,
  ExperiencesResponse,
  ExtraCurricularsResponse,
} from './candidate';
import { CitiesResponse } from './city';
import { CompanyResponse } from './company';
import { DegreeResponse } from './degree';
import { StudentSocietyResponse } from './student-society';
import { SubjectResponse } from './subject';
import { TagType, TagsResponse } from './tag';
import { UniversityResponse } from './university';

export type TUser = {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  authentication_token: string;
  referral_code: string;
  created_at: string;
  userable_id: string;
  userable_type: TUserableType;
  oauth_provider: 'google' | 'apple' | null;
  is_admin: boolean;
  is_approved: boolean;
  is_confirmed: boolean;
};

type TUserData = {
  id: string;
  type: 'user';
  attributes: TUser;
};

type TUserResponse = {
  data: TUserData;
};

type UserAttributesCommon = {
  user: TUserResponse;
};

type ToolUserAttributesCommon = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  authentication_token: string;
  created_at: string;
  avatar_url: string | null;
  flow_state: string | null;
  role: 'member' | null;
};

export type TManager = UserAttributesCommon &
  ToolUserAttributesCommon & {
    student_society: StudentSocietyResponse;
    linktree_url: string;
  };

export type TRecruiter = UserAttributesCommon &
  ToolUserAttributesCommon & {
    company: CompanyResponse;
    sponsorship_student_society_id: string;
    sponsorship_message: string;
    sponsorship_plan_id: string;
  };

export type TCandidate = UserAttributesCommon & {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  is_visible_to_recruiters: boolean;
  date_of_birth: string;
  exploring_status: boolean;
  resume: CandidateResume;
  resume_url: string;
  avatar_url: string;
  notifications_enabled: boolean;
  score: number;
  study_year: number;
  focus: null;
  matches_count: number;
  unvisited_matches_count: number;
  applications_count: number;
  accepted_applications_count: number;
  linkedin_url: string;
  instagram_url: string;
  bio: string;
  notifications_count: number;
  university: UniversityResponse;
  is_privacy_terms_accepted: boolean;
  degree: DegreeResponse;
  subject: SubjectResponse;
  opportunity_types: Array<string>;
  cities: CitiesResponse;
  remote_opportunity: boolean | null;
  reviewed_preferences: boolean;
  touchpointable_kinds: Array<string> | null;
  event_category_ids: Array<string>;
  onboarding_completed: boolean;
  next_job_state_empty: boolean;
  next_internship_state_empty: boolean;
  next_event_state_empty: boolean;
  flow_state: FLOW | null;
  phone_number: string;
  location: string;
  education_histories: EducationHistoriesResponse;
  work_histories: ExperiencesResponse;
  extra_curricular_activities: ExtraCurricularsResponse;
  accomplishments: AccomplishmentsResponseResponse;
  country_ids: Array<string>;
  gender: string;
  physical_disability: string;
  congnitive_disability: string;
  socio_economic_status: string;
  ethnicities: TagsResponse<TagType.Ethnicity>;
  languages: TagsResponse<TagType.Language>;
  country_id: string;
  display_huzzle_verified: boolean;
  viewed_opportunity_count: Record<string, number>;
  huzzle_verified: boolean;
  visa_country_ids: Array<string>;
  language_ids: Array<string>;
  show_add_language_banner: boolean;
  show_cv_builder_banner: boolean;
  show_cv_builder_popup: boolean;
  show_affiliated_societies: boolean;
  show_complete_profile_banner: boolean;
  show_chrome_externsion_banner: boolean;
  profile_complete: boolean;
  extension_installed: boolean;
  experience_preferences: Array<number>;
  /** city_ids field is only for updating Location preference on Onboarding and MP
   * This is not received in candidate atttributes
   */
  // city_ids?: string[];
  matches_email_frequency: MatchesEmailFrequency;
  show_matches_frequency_banner: boolean;
  graduation_year: number;
  external_applied_non_event_count: number;
  external_registrated_event_count: number;
  saved_event_count: number;
  saved_non_event_count: number;
  company_follow_count: number;
  student_society_follow_count: number;
  show_avatar_in_cv: boolean;
  show_bio_in_cv: boolean;
  show_profile_splash_screen: boolean;
  pro: false;
  pro_start_date: string | null;
  pro_end_date: string | null;
};

export type TUserApiAttributes = TManager | TRecruiter | TCandidate;

export type TUserApiData = {
  id: string;
  type: TUserType;
  attributes: TUserApiAttributes;
};

export enum TUserableType {
  CANDIDATE = 'Candidate',
  MANAGER = 'Manager',
  RECRUITER = 'Recruiter',
}

export enum TUserType {
  RECRUITER = 'recruiter',
  MANAGER = 'manager',
  CANDIDATE = 'candidate',
  ADMIN = 'admin',
}

export type TUserApiResponse = {
  data: TUserApiData;
};

export type UserAvatarFields = {
  id: string;
  name: string;
  image_url: string;
  is_huzzle_verified: boolean;
  is_verified: boolean;
  userable_type: TUserableType;
  team_member_id: string | null;
};
