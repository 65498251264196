import platform from 'platform';

/*
 * isClient
 * condition whether server-side or client-side
 */
export const isClient: boolean = typeof window === 'object';

/*
 * isTouchDevice
 * condition if device has touch event
 */
export const isTouchDevice: boolean = isClient && 'ontouchstart' in window;

/**
 *
 * @returns the inner height of the device
 */
export const getInnerHeight = (): number => window.innerHeight;

/**
 *
 * @returns the inner width of the device
 */
export const getInnerWidth = (): number => window.innerWidth;

export const isAndroid = (): boolean => {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  return userAgent.includes('Android');
};

export const isMobileDevice = (): boolean => {
  return typeof navigator === 'undefined'
    ? false
    : /iPhone|iPad|iPod|Android|Mobile/i.test(navigator.userAgent);
};

export const isiPhoneSafari =
  platform.name === 'Safari' && platform.product === 'iPhone';

export const isSafari = (): boolean => {
  const userAgentString =
    typeof navigator === 'undefined' ? '' : navigator.userAgent;
  // Detect Chrome
  const chromeAgent = userAgentString.indexOf('Chrome') > -1;
  // Detect Safari
  const safariAgent = userAgentString.indexOf('Safari') > -1;

  // Discard Safari since it also matches Chrome
  if (safariAgent) {
    if (chromeAgent) {
      return false;
    } else {
      return true;
    }
  } else return false;
};
