import React, { useMemo } from 'react';
import cx from 'classnames';

import { TTextPost } from 'lib/models/discussion';
import { findURLs } from 'lib/utils/discussion';

import styles from './MessageContent.module.scss';

type MessageContentProps = {
  content: TTextPost['content'];
  hasDarkBackground?: boolean;
  linkUnderlined?: boolean;
};

const MessageContent: React.FC<MessageContentProps> = ({
  content,
  hasDarkBackground = false,
  linkUnderlined = false,
}) => {
  const text = useMemo(() => {
    const urls = findURLs(content);
    let text = content;
    urls?.forEach((url) => {
      text = text.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
    });
    return text;
  }, [content]);

  return (
    <div
      className={cx(styles['plain-text'], {
        [styles['plan-text-sender']]: hasDarkBackground,
        [styles['dark-bg']]: hasDarkBackground,
        [styles['link-underlined']]: linkUnderlined,
      })}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    ></div>
  );
};

MessageContent.displayName = 'MessageContent';

export default MessageContent;
