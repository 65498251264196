import React, { memo } from 'react';
import cn from 'classnames';

import styles from './Spinner.module.scss';

export interface SpinnerProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** size */
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'inherit';
  /** color */
  color?: 'primary' | 'secondary' | 'complementary-primary' | 'black';
  /** wrap in inside full height, width container */
  wrapper?: boolean;
  wrapperClassname?: string;
  showBackDrop?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    size = 'medium',
    color = 'primary',
    wrapper = false,
    showBackDrop = false,
    wrapperClassname = '',
  }: SpinnerProps) => {
    const contentClassNames = cn(
      {
        [styles.container]: true,
        [styles[`${size}`]]: size,
        [styles[`${color}`]]: color,
      },
      className
    );

    return (
      <div
        className={cn(
          { [styles.wrapper]: wrapper },
          { [styles.spinnerBackdrop]: showBackDrop },
          wrapperClassname
        )}
      >
        <div className={contentClassNames} style={style} data-testid="Spinner">
          <div className={styles.spinner}>
            <svg
              height="100%"
              width="100%"
              viewBox="0 0 150 150"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="75" cy="75" r="62" />
            </svg>
          </div>
        </div>
      </div>
    );
  }
);

Spinner.displayName = 'Spinner';
