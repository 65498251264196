import React, { memo, useState, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { Listbox } from '@headlessui/react';

import { Icon } from 'components';
import { Option } from 'lib/models/option';

import styles from './DropdownV2.module.scss';

export type DropdownOption = Option & {
  groupEnd?: boolean;
  isDelete?: boolean;
  isDefault?: boolean;
  industry_ids?: Array<string>;
};

export interface DropdownV2Props {
  /** Custom class name */
  className?: string;
  /** title */
  title?: string;
  /** label */
  label: string;
  /** options */
  options: Array<DropdownOption>;
  /** is it searchable? */
  isSearchable?: boolean;
  /** placeholder text for search */
  searchPlaceholder?: string;
  /** disabled */
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
  selectedValueLabel: string;
  isValid: boolean;
  helperText?: string;
  /** Show when there is no match */
  noResultsOption?: string;
  labelClassName?: string;
  hasBorder?: boolean;
  showOptionalLabel?: boolean;
  triggerButtonClassName?: string;
  labelStyle?: Record<string, unknown>;
  isDefaultTriggerClass?: boolean;
  isArrowIcon?: boolean;
  optionsClassName?: string;
}

export const DropdownV2: React.FC<DropdownV2Props> = memo(
  ({
    className = '',
    options,
    title,
    label,
    value,
    onChange,
    isSearchable = false,
    searchPlaceholder,
    disabled,
    selectedValueLabel,
    isValid,
    helperText,
    noResultsOption,
    labelClassName = '',
    hasBorder,
    showOptionalLabel,
    triggerButtonClassName = '',
    labelStyle,
    isDefaultTriggerClass = true,
    isArrowIcon,
    optionsClassName = '',
  }: DropdownV2Props) => {
    const contentClassNames = cn(styles.container, className);
    const [search, setSearch] = useState('');
    const trimmedSearch = search.trim();

    const filteredOptions = useMemo(() => {
      const list = isSearchable
        ? options.filter(
            (option) =>
              option.label.toLowerCase().indexOf(trimmedSearch.toLowerCase()) >
              -1
          )
        : options;

      if (list.length > 0) return list;

      if (noResultsOption) {
        const option = options.find(
          (option) =>
            option.label.toLowerCase() === noResultsOption.toLowerCase()
        );
        if (option) return [option];
        else return list;
      } else {
        return list;
      }
    }, [trimmedSearch, options, isSearchable, noResultsOption]);

    const handleChange = useCallback((value) => {
      setSearch('');
      onChange(value);
    }, []);

    const onSearchKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      event.stopPropagation();
    };

    const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
      event
    ) => {
      setSearch(event.target.value);
    };

    const focusInput = useCallback((ref) => {
      setTimeout(() => {
        if (ref) ref.focus();
      }, 200);
    }, []);

    return (
      <Listbox
        as="div"
        className={contentClassNames}
        value={value}
        onChange={handleChange}
      >
        {(title || (!isValid && helperText)) && (
          <div className={styles.header}>
            {title && (
              <label
                className={cn(styles.label, labelClassName)}
                style={labelStyle}
              >
                {title}
                {showOptionalLabel ? <span> (optional)</span> : null}
              </label>
            )}
            {!isValid && helperText && (
              <span className={styles.error}>{helperText}</span>
            )}
          </div>
        )}
        <Listbox.Button
          className={cn({
            [styles.trigger]: isDefaultTriggerClass,
            [styles.hasborder]: hasBorder,
            [triggerButtonClassName]: true,
          })}
          type="button"
          aria-disabled={disabled}
        >
          {selectedValueLabel ? (
            <span className={styles.selectedValue}>{selectedValueLabel}</span>
          ) : (
            <span className={styles.buttonLabel}>{label}</span>
          )}
          {!disabled && !isArrowIcon && (
            <Icon iconName="chevron-down" className={styles.chevron} />
          )}
          {isArrowIcon ? (
            <Icon iconName="icon_arrow-down" className={styles.chevron} />
          ) : null}
        </Listbox.Button>
        <Listbox.Options className={cn(styles.dropdown, optionsClassName)}>
          {isSearchable ? (
            <div className={styles.search}>
              <input
                ref={focusInput}
                value={search}
                placeholder={searchPlaceholder}
                onKeyDown={onSearchKeyDown}
                onChange={onSearchChange}
              />
              <Listbox.Button className={styles.close}>
                <Icon iconName="chevron-up" />
              </Listbox.Button>
            </div>
          ) : null}
          <div className={styles.options}>
            {filteredOptions.map((option) => {
              const { id, value, label, groupEnd, isDelete } = option;
              return (
                <Listbox.Option
                  key={id}
                  value={value}
                  className={cn(styles.option, { [styles.groupEnd]: groupEnd })}
                >
                  <span className={cn({ [styles.deleteLabel]: isDelete })}>
                    {label}
                  </span>
                  <Icon
                    iconName="valid-check-mark"
                    className={styles.checkMark}
                  />
                </Listbox.Option>
              );
            })}
          </div>
        </Listbox.Options>
      </Listbox>
    );
  }
);

DropdownV2.displayName = 'DropdownV2';
