import React, { memo, useState, forwardRef } from 'react';
import cn from 'classnames';

import Input, { InputProps } from 'components/form/Input';

import styles from './Password.module.scss';

export interface PasswordProps extends InputProps {
  rightContext?: JSX.Element;
  version?: 'v1' | 'v2';
  inputClassName?: string;
}
// Note if right context is there need to change the position for helper text for error message

export const Password = memo(
  forwardRef<HTMLInputElement, PasswordProps>((props, ref) => {
    const [visibilityOn, setVisibility] = useState(false);

    const { rightContext, ...rest } = props;

    return (
      <div className={styles.Password}>
        {rightContext && (
          <div className={styles.rightContext}>{rightContext}</div>
        )}
        <Input
          {...rest}
          ref={ref}
          type={visibilityOn ? 'text' : 'password'}
          endIcon={visibilityOn ? 'eye-open' : 'eye-close'}
          onEndIconClick={() => setVisibility(!visibilityOn)}
          endIconSize="large"
          inputClassName={cn(
            {
              [styles.inputV2]: props.version === 'v2',
              [styles.inputInvalid]: !props.isValid,
            },
            props.inputClassName
          )}
        />
      </div>
    );
  })
);

Password.displayName = 'Password';
