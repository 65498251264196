export const GTM_KEY = process.env.NEXT_PUBLIC_GTM_KEY;

export const pageview = (): void => {
  // @ts-ignore
  if (dataLayer?.push)
    // @ts-ignore
    dataLayer.push({
      event: 'pageview',
      path: window.location.pathname + window.location.search,
    });
};
