import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { pageview, FB_PIXEL_ID } from 'lib/fpixel';

export const FacebookPixel: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    if (!FB_PIXEL_ID) return;
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    pageview();

    const handleRouteChange = () => {
      pageview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, FB_PIXEL_ID]);

  // @ts-ignore
  if (!FB_PIXEL_ID || (typeof window !== 'undefined' && window.fbq))
    return null;

  return (
    <Script
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
          `,
      }}
    />
  );
};

FacebookPixel.displayName = 'FacebookPixel';
