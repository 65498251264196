import { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import { AxiosResponse } from 'axios';

import { MatchAttributes, MatchesResponse } from 'lib/models/matches';
import { useAuth } from 'lib/providers/AuthProvider';
import { API_ROUTES } from 'lib/api-routes';
import { apiInstance } from 'lib/utils/axios';
import { parseArrayResponse } from 'lib/utils/parser';
import { TouchpointType } from 'lib/models/touchpoint';
import { TUserApiResponse } from 'lib/models/User.model';

export enum MatchesEmailFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  NEVER = 'never',
}

export const useCandidateMatchesInternalHook = () => {
  const { isCandidate, updateUserApiResponse } = useAuth();
  // When matches are getting generated from scratch
  const [isMatchesGenerating, setIsMatchesGenerating] = useState(false);
  // When new matches are getting generated
  const [isMatchesRegenerating, setIsMatchesRegenerating] = useState(false);

  const [isPreferenceModified, setPreferenceModified] = useState(false);

  const {
    data: matchesResponse,
    mutate: mutateMatches,
    isLoading: isMatchesLoading,
  } = useSWR<AxiosResponse<MatchesResponse>>(isCandidate ? API_ROUTES.MATCHES : null, apiInstance.get, { revalidateOnFocus: false });

  const [opportunityMatches, jobMatches, internshipMatches, eventMatches, matchesMeta] = useMemo(() => {
    if (!matchesResponse) return [[] as MatchAttributes[], [] as MatchAttributes[], [] as MatchAttributes[], [] as MatchAttributes[], null];

    const {
      data: { touchpoints, meta },
    } = matchesResponse;
    const { jobs: jobsResponse, internships: internshipsResponse, events: eventsResponse } = touchpoints;
    const jobsMatches = parseArrayResponse(jobsResponse);
    const internshipsMatches = parseArrayResponse(internshipsResponse);
    const eventsMatches = parseArrayResponse(eventsResponse);
    const opportunityMatches = [...jobsMatches, ...internshipsMatches, ...eventsMatches];

    return [opportunityMatches, jobsMatches, internshipsMatches, eventsMatches, meta];
  }, [matchesResponse]);

  // View a match: update viewed_at field of a match and update the meta data
  const viewMatch = useCallback(async ({ matchId, touchpointType }: { matchId: string; touchpointType: TouchpointType }) => {
    const pathname = `${API_ROUTES.UPDATE_MATCHES}/${matchId}`;
    const viewedAt = new Date().toISOString();
    const body = { match: { viewed_at: viewedAt } };
    try {
      apiInstance.patch(pathname, body);
      mutateMatches(
        (oldMatchesResponse) => {
          if (!oldMatchesResponse) return oldMatchesResponse;
          const { data } = oldMatchesResponse;
          const { touchpoints, meta } = data;
          if (meta.unvisited_count === 0) return oldMatchesResponse;

          let updatedMeta = { ...meta, unvisited_count: meta.unvisited_count - 1, viewed_count: meta.viewed_count + 1 };
          switch (touchpointType) {
            case TouchpointType.Job:
              updatedMeta = {
                ...updatedMeta,
                unvisited_jobs_count: updatedMeta.unvisited_jobs_count - 1,
                viewed_jobs_count: updatedMeta.viewed_jobs_count + 1,
              };
              break;
            case TouchpointType.Internship:
              updatedMeta = {
                ...updatedMeta,
                unvisited_internships_count: updatedMeta.unvisited_internships_count - 1,
                viewed_internships_count: updatedMeta.viewed_internships_count + 1,
              };
              break;
            case TouchpointType.Event:
              updatedMeta = {
                ...updatedMeta,
                unvisited_events_count: updatedMeta.unvisited_events_count - 1,
                viewed_events_count: updatedMeta.viewed_events_count + 1,
              };
              break;
            default:
              break;
          }

          const updatedTouchpoints = {
            ...touchpoints,
            jobs: {
              ...touchpoints.jobs,
              data: touchpoints.jobs.data.map((job) =>
                job.id === matchId ? { ...job, attributes: { ...job.attributes, viewed_at: viewedAt } } : job
              ),
            },
            internships: {
              ...touchpoints.internships,
              data: touchpoints.internships.data.map((internship) =>
                internship.id === matchId ? { ...internship, attributes: { ...internship.attributes, viewed_at: viewedAt } } : internship
              ),
            },
            events: {
              ...touchpoints.events,
              data: touchpoints.events.data.map((event) =>
                event.id === matchId ? { ...event, attributes: { ...event.attributes, viewed_at: viewedAt } } : event
              ),
            },
          };
          const res = { ...oldMatchesResponse, data: { ...data, meta: updatedMeta, touchpoints: updatedTouchpoints } };
          return res;
        },
        { revalidate: false }
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  const generateNewMatches = useCallback(async () => {
    try {
      setIsMatchesRegenerating(true);
      const response = await apiInstance.get<MatchesResponse>(`${API_ROUTES.MATCHES}&generate_now=true`);
      mutateMatches(response, { revalidate: false });
    } catch (error) {
      console.error(error);
    } finally {
      setIsMatchesRegenerating(false);
    }
  }, [mutateMatches, setIsMatchesRegenerating]);

  const generateMatchesFromScratch = useCallback(async () => {
    try {
      setIsMatchesGenerating(true);
      const response = await apiInstance.get<MatchesResponse>(`${API_ROUTES.MATCHES}&generate_from_scratch=true`);
      mutateMatches(response, { revalidate: false });
    } catch (error) {
      console.error(error);
    } finally {
      setIsMatchesGenerating(false);
    }
  }, [mutateMatches, setIsMatchesRegenerating]);

  const updateCandidatePreferredMatches = useCallback(
    async ({ match_ids }: { match_ids: Array<string> }) => {
      try {
        const body = { match_ids };
        const { data: response } = await apiInstance.post<TUserApiResponse>(API_ROUTES.MATCH_PREFERENCES, body);
        updateUserApiResponse(response);
        return !!response;
      } catch (error) {
        console.error(error);
      }
    },
    [updateUserApiResponse]
  );

  const refetchCurrentMatches = useCallback(async () => {
    try {
      await mutateMatches();
    } catch (error) {
      console.error(error);
    }
  }, [mutateMatches]);

  return {
    isMatchesLoading,
    opportunityMatches,
    jobMatches,
    internshipMatches,
    eventMatches,
    matchesMeta,
    isMatchesGenerating,
    isMatchesRegenerating,
    refetchCurrentMatches,
    generateMatchesFromScratch,
    generateNewMatches,
    viewMatch,
    updateCandidatePreferredMatches,
    isPreferenceModified,
    setPreferenceModified,
  };
};

export type UseCandidateMatchesReturnType = ReturnType<typeof useCandidateMatchesInternalHook>;
