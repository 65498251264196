import React, { forwardRef, memo } from 'react';
import cn from 'classnames';

import type { SelectionProps } from '../Selection';
import Selection from '../Selection';

import styles from './SelectionCheckbox.module.scss';

export type SelectionCheckboxProps = Omit<SelectionProps, 'type'> & {
  hasBorder?: boolean;
};

export const SelectionCheckbox = memo(
  forwardRef<HTMLInputElement, SelectionCheckboxProps>(
    ({ className = '', style, hasBorder, children, ...props }, ref) => {
      const contentClassNames = cn(styles.SelectionCheckbox, className, {
        [styles['has-horder']]: hasBorder,
      });

      return (
        <Selection
          {...props}
          className={contentClassNames}
          style={style}
          type="checkbox"
          dataTestid="SelectionCheckbox"
          ref={ref}
        >
          {children}
        </Selection>
      );
    }
  )
);

SelectionCheckbox.displayName = 'SelectionCheckbox';
