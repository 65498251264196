import {
  useCallback,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  MutableRefObject,
} from 'react';
import useSWRInfinite from 'swr/infinite';
import queryString from 'query-string';

import useDebounce from 'hooks/useDebounce';
import { get } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import { parsePaginatedResponse } from 'lib/utils/parser';

import {
  StudentSocietiesResponse,
  StudentSocietyAttributes,
} from 'lib/models/student-society';
import { SelectedFilterOptionLabels, SelectedFilterOptions } from './useFilter';

const PER_PAGE_COUNT = 50;

export interface ReturnType {
  societies: Array<StudentSocietyAttributes>;
  resetFilter: () => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  size: number;
  setSize: (size: number) => void;
  hasMoreRecords: boolean;
  isLoading: boolean;
  selectedOptions: SelectedFilterOptions;
  setSelectedOptions: Dispatch<SetStateAction<SelectedFilterOptions>>;
  selectedLabelsRef: MutableRefObject<SelectedFilterOptionLabels>;
  isLoadingMore: boolean | undefined;
}

export const useSocietiesSearch = (): ReturnType => {
  const [search, setSearch] = useState('');
  const trimmedSearch = search.trim();
  const debouncedSearch = useDebounce(trimmedSearch, 500);
  const [selectedOptions, setSelectedOptions] = useState<SelectedFilterOptions>(
    {}
  );
  const selectedLabelsRef = useRef<SelectedFilterOptionLabels>({});
  const resetFilter = useCallback(() => {
    setSearch('');
    selectedLabelsRef.current = {};
    setSelectedOptions({});
  }, []);

  const getSocietiesKey = (pageIndex: number) => {
    const params = {
      name: debouncedSearch,
      page: pageIndex + 1,
      per_page: PER_PAGE_COUNT,
      ...selectedOptions,
    };
    const qs = queryString.stringify(params, {
      arrayFormat: 'bracket',
      skipEmptyString: true,
    });
    return `${API_ROUTES.STUDENT_SOCIETIES}?${qs}`;
  };

  const {
    data: studentSocietiesResponse,
    error: studentSocietiesError,
    size,
    setSize,
  } = useSWRInfinite<StudentSocietiesResponse>(getSocietiesKey, get, {
    revalidateOnFocus: false,
    revalidateFirstPage: false,
  });

  const isLoading = !studentSocietiesResponse && !studentSocietiesError;

  const isLoadingMore =
    isLoading ||
    (size > 0 &&
      studentSocietiesResponse &&
      typeof studentSocietiesResponse[size - 1] === 'undefined');

  const societies = useMemo(() => {
    return parsePaginatedResponse<StudentSocietyAttributes>(
      studentSocietiesResponse
    );
  }, [studentSocietiesResponse]);

  const hasMoreRecords = useMemo(() => {
    const firstSocietyList = Array.isArray(studentSocietiesResponse)
      ? studentSocietiesResponse[0]
      : null;
    if (firstSocietyList) {
      return firstSocietyList.meta.total > size * PER_PAGE_COUNT;
    } else {
      return false;
    }
  }, [studentSocietiesResponse, size]);

  return {
    societies,
    resetFilter,
    search,
    setSearch,
    size,
    setSize,
    hasMoreRecords,
    isLoading,
    selectedOptions,
    setSelectedOptions,
    selectedLabelsRef,
    isLoadingMore,
  };
};
