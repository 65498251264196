import { useState } from 'react';
import { TouchpointType } from 'lib/models/touchpoint';
import { apiInstance } from 'lib/utils/axios';
import { API_ROUTES } from 'lib/api-routes';
import { PAGE_ROUTES } from 'lib/page-routes';

type SelectedTouchpoint = {
  id: string;
  touchpointable_type: TouchpointType;
};
export const useSociety = () => {
  //to set the selected touchpoints in the career opportunity page
  const [selectedTouchpoints, setSelectedTouchpoints] = useState<
    SelectedTouchpoint[]
  >([]);
  const filterTouchpoints = (type: TouchpointType | 'All') => {
    if (type === 'All') {
      return selectedTouchpoints;
    }
    return selectedTouchpoints.filter(
      (touchpoint) => touchpoint.touchpointable_type === type
    );
  };

  const getCopiedLink = async (type: TouchpointType | 'All' = 'All') => {
    const filteredTouchpoints = filterTouchpoints(type);
    try {
      const res = await apiInstance.post(API_ROUTES.ID_LISTS, {
        ids: filteredTouchpoints.map(
          (selectedTouchpoint) => selectedTouchpoint.id
        ),
      });
      let linkToCopy = `${process.env.NEXT_PUBLIC_SITE_URL}${PAGE_ROUTES.SEARCH_RESULTS}?id_list_ids=${res.data.id}`;
      if (type === TouchpointType.Job) {
        linkToCopy += '&type=Jobs';
      } else if (type === TouchpointType.Internship) {
        linkToCopy += '&type=internships';
      }
      return linkToCopy;
    } catch (err) {
      console.error(err);
    }
  };

  return {
    selectedTouchpoints,
    setSelectedTouchpoints,
    getCopiedLink,
  };
};

export type SocietyGlobalReturnType = ReturnType<typeof useSociety>;
