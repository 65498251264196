import {
  Dispatch,
  SetStateAction,
  MutableRefObject,
  useState,
  useCallback,
  useRef,
} from 'react';

import { SelectedFilterOptionLabels, SelectedFilterOptions } from './useFilter';

export interface ReturnType {
  selectedOptions: SelectedFilterOptions;
  setSelectedOptions: Dispatch<SetStateAction<SelectedFilterOptions>>;
  selectedLabelsRef: MutableRefObject<SelectedFilterOptionLabels>;
  onClickFilter: (key: string, value: string) => void;
  onChangeFilter: (key: string, value: Array<string>, labels: string[]) => void;
  resetFilter: () => void;
}

export const useManagerFilters = (): ReturnType => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedFilterOptions>(
    {}
  );
  const selectedLabelsRef = useRef<SelectedFilterOptionLabels>({});

  const onChangeFilter = useCallback(
    (key: string, values: string[], labels: string[]) => {
      setSelectedOptions({ ...selectedOptions, [key]: values });
      selectedLabelsRef.current[key] = labels;
    },
    [selectedOptions]
  );

  const onClickFilter = useCallback(
    (key: string, value: string) => {
      setSelectedOptions({
        ...selectedOptions,
        [key]: value,
      });
      selectedLabelsRef.current = {};
    },
    [selectedOptions]
  );

  const resetFilter = useCallback(() => {
    selectedLabelsRef.current = {};
    setSelectedOptions({});
  }, []);

  return {
    selectedOptions,
    setSelectedOptions,
    selectedLabelsRef,
    onClickFilter,
    onChangeFilter,
    resetFilter,
  };
};
