import React, { useContext, createContext } from 'react';

import { noop } from 'lodash';
import {
  UseCandidateMatchesReturnType,
  useCandidateMatchesInternalHook,
} from 'hooks/useCandidateMatches/useCandidateMatchesInternal';

const initialContext: UseCandidateMatchesReturnType = {
  opportunityMatches: [],
  jobMatches: [],
  internshipMatches: [],
  eventMatches: [],
  isMatchesLoading: false,
  matchesMeta: null,
  isMatchesGenerating: false,
  isMatchesRegenerating: false,
  viewMatch: async () => Promise.resolve(),
  refetchCurrentMatches: async () => Promise.resolve(),
  generateNewMatches: () => Promise.resolve(),
  generateMatchesFromScratch: () => Promise.resolve(),
  updateCandidatePreferredMatches: async () => Promise.resolve(true),
  isPreferenceModified: false,
  setPreferenceModified: noop,
};

const CandidateMatchesContext =
  createContext<UseCandidateMatchesReturnType>(initialContext);

export const useCandidateMatches = () => useContext(CandidateMatchesContext);

export const CandidateMatchesProvider: React.FC = ({ children }) => {
  const value = useCandidateMatchesInternalHook();

  return (
    <CandidateMatchesContext.Provider value={value}>
      {children}
    </CandidateMatchesContext.Provider>
  );
};
