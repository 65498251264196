import cn from 'classnames';
import { ReactElement, useMemo } from 'react';

import { Icon, IconButton, IconName, Spinner } from 'components';
import { NotificationTypeEnum } from 'lib/models/notification';

import styles from './ToasterV2.module.scss';

type ToasterV2Props = {
  title?: string;
  message: string | ReactElement;
  type: NotificationTypeEnum;
  loading?: boolean;
  icon?: IconName;
  handleClose?: () => void;
  variant?: 'medium' | 'small' | 'micro';
  borderRounded?: boolean;
};

const ToasterV2: React.FC<ToasterV2Props> = ({
  message,
  loading,
  icon,
  type,
  handleClose,
  variant = 'micro',
  borderRounded = true,
  title,
}) => {
  const isFailure = useMemo(
    () => type === NotificationTypeEnum.Failure,
    [type]
  );

  const getIconSize = useMemo(() => {
    if (variant === 'medium') {
      return 'largeplus';
    } else if (variant === 'small') {
      return 'xmediumplus';
    } else {
      return 'small';
    }
  }, [variant]);

  const getIcon = useMemo(() => {
    if (icon) return icon;

    return 'icon_check-double';
  }, [icon]);

  const toasterMessage = useMemo(() => {
    if (typeof message === 'string') {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: message.replace(/\n/g, '<br>'),
          }}
          //micro variant have only message which should be shown as title
          className={variant === 'micro' ? styles.title : styles.message}
        />
      );
    } else return message;
  }, [message]);

  return (
    <div
      className={cn(styles.container, {
        [styles.failure]: isFailure,
        [styles.micro]: variant === 'micro',
        [styles.borderRounded]: borderRounded,
        [styles.small]: variant === 'small',
        [styles.medium]: variant === 'medium',
      })}
    >
      <div className={styles.content}>
        {loading ? (
          <Spinner size="xxsmall" color="secondary" />
        ) : (
          <Icon
            iconName={getIcon}
            size={getIconSize}
            className={styles['icon-start']}
          />
        )}
        <div>
          {title && (
            <p
              dangerouslySetInnerHTML={{
                __html: title.replace(/\n/g, '<br>'),
              }}
              className={styles.title}
            />
          )}
          {toasterMessage}
        </div>
      </div>
      {handleClose ? (
        <IconButton
          iconName="icon_close"
          size={variant === 'micro' ? 'xsmall' : 'small'}
          onClick={handleClose}
          className={cn(styles.closeIcon, {
            [styles['not-micro']]: variant !== 'micro',
          })}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

ToasterV2.displayName = 'ToasterV2';

export default ToasterV2;
