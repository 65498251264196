import React, { forwardRef } from 'react';
import cn from 'classnames';

import { Spinner } from 'components';

import styles from './ButtonV2.module.scss';

export interface ButtonV2Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Custom class name */
  className?: string;
  /** size */
  size?: 'medium' | 'large';
  /** width */
  width?: number | string;
  /** is full width? */
  isFullWidth?: boolean;
  /** is loading? */
  isLoading?: boolean;
  /** children */
  children: React.ReactNode;
  outlined?: boolean;
  padding?: string;
}

export const ButtonV2: React.FC<ButtonV2Props> = forwardRef<
  HTMLButtonElement,
  ButtonV2Props
>(
  (
    {
      className = '',
      size = 'medium',
      width,
      isFullWidth,
      isLoading = false,
      onClick,
      children,
      /* Make changes for funnel-matches */
      outlined = false,
      padding,
      ...props
    },
    ref
  ) => {
    const contentClassNames = cn(
      {
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
        [styles.fullWidth]: isFullWidth,
        [styles.loading]: isLoading,
        [styles.outlined]: outlined,
      },
      className,
      styles.button
    );

    return (
      <button
        className={contentClassNames}
        data-testid="ButtonV2"
        style={{ width, padding }}
        ref={ref}
        onClick={isLoading ? undefined : onClick}
        {...props}
      >
        {children}
        {isLoading && (
          <Spinner className={styles.spinner} size="xsmall" color="secondary" />
        )}
      </button>
    );
  }
);

ButtonV2.displayName = 'ButtonV2';
