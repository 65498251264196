import { CohostResponse } from './cohost';
import { Place } from './event';
import { StudentSocietyResponse } from './student-society';

export enum TouchpointOpportunity {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export enum TouchpointStatus {
  SAVED = 'saved',
  STARTED = 'started',
  APPLIED = 'applied', // applied status is for both applied and registered
}

export enum TouchpointType {
  Job = 'Job',
  Internship = 'Internship',
  Event = 'Event',
}

export interface TouchpointAttributes {
  id: string;
  title: string;
  touchpointable_type: TouchpointType;
  enabled: boolean;
  impressions_count: number;
  conversion_rate: number;
  applications: number;
  kind: string;
  slug: string;
  start_date: string;
  state: string;
  expired_at: string;
  view_counter: number;
  creatable_for: StudentSocietyResponse;
  cohost_application?: CohostResponse | null;
  is_online: boolean;
  online_link: string;
  place: Place;
  rating_map: Record<string, number>;
  apply_url?: string;
  unreviewed_applications_count: number;
  featured: boolean;
}

export interface Touchpoint {
  id: string;
  type: 'touchpoint';
  attributes: TouchpointAttributes;
}

export interface TouchpointResponse {
  data: Touchpoint;
}

export interface TouchpointsResponse {
  data: Touchpoint[];
  meta: {
    total: number;
  };
}

export const TouchpointTypeImage = {
  [TouchpointType.Job]: 'job.png',
  [TouchpointType.Internship]: 'internship.png',
  [TouchpointType.Event]: 'event.png',
};

export enum LocationType {
  IN_PERSON = 'In Person',
  VIRTUAL = 'Virtual',
}

export const locationOptions = [
  {
    id: LocationType.IN_PERSON,
    value: LocationType.IN_PERSON,
    label: LocationType.IN_PERSON,
  },
  {
    id: LocationType.VIRTUAL,
    value: LocationType.VIRTUAL,
    label: LocationType.VIRTUAL,
  },
];
