import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  useManagerFilters as useFilteredManagerReturnTypeInternal,
  ReturnType as useManagerFiltersReturnType,
} from 'hooks/useManagerFilters';

const initialContext: useManagerFiltersReturnType = {
  selectedOptions: {},
  setSelectedOptions: noop,
  selectedLabelsRef: { current: {} },
  onClickFilter: () => noop,
  onChangeFilter: () => noop,
  resetFilter: () => noop,
};

const ManagerFiltersContext =
  createContext<useManagerFiltersReturnType>(initialContext);

export const useManagerFilters = (): useManagerFiltersReturnType =>
  useContext(ManagerFiltersContext);

export const ManagerFiltersContextProvider: React.FC = ({ children }) => {
  const value = useFilteredManagerReturnTypeInternal();

  return (
    <ManagerFiltersContext.Provider value={value}>
      {children}
    </ManagerFiltersContext.Provider>
  );
};
