import { AxiosResponse } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';

import { FLOW } from 'hooks/useApp';
import { API_ROUTES } from 'lib/api-routes';
import { TCandidateDashboard } from 'lib/models/candidate';
import { useAuth } from 'lib/providers/AuthProvider';
import { apiInstance } from 'lib/utils/axios';
import { TUserApiResponse } from 'lib/models/User.model';
import { TBasicStudentSocietyWithPostsStat } from 'lib/models/discussion';

export const useCandidateDashboardInternalHook = () => {
  const { isCandidate, candidate, updateUserApiResponse } = useAuth();
  const [isCandidateLastStepLoading, setIsCandidateLastStepLoading] = useState(false);
  const { data: candidateDashboardResponse, mutate } = useSWR<AxiosResponse<TCandidateDashboard>>(
    isCandidate ? API_ROUTES.CANDIDATE_DASHBOARD : null,
    apiInstance.get,
    {
      revalidateOnFocus: false,
    }
  );

  const candidateLastUpdatedStep = useMemo(() => candidate?.flow_state || null, [candidate]);

  const updateCandidatesLastStep = useCallback(
    async (flow_state: FLOW | null) => {
      try {
        setIsCandidateLastStepLoading(true);
        const body = { candidate: { flow_state } };
        const { data: response } = await apiInstance.put<TUserApiResponse>(API_ROUTES.CANDIDATE, body);
        updateUserApiResponse(response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsCandidateLastStepLoading(false);
      }
    },
    [updateUserApiResponse]
  );

  const candidateDashboard = useMemo(() => candidateDashboardResponse?.data || null, [candidateDashboardResponse]);

  const refetchCandidateDashboard = useCallback(async () => await mutate(), [mutate]);

  const { data: interactedSocietiesResponse } = useSWR<AxiosResponse<Array<TBasicStudentSocietyWithPostsStat>>>(
    isCandidate ? API_ROUTES.INTERACTED_STUDENT_SOCIETIES : null,
    apiInstance.get,
    {
      revalidateOnFocus: false,
    }
  );

  const totalUnreadPostCount = useMemo(() => {
    const interactedSocieties = interactedSocietiesResponse?.data
      ?.filter((society) => !!society.last_post.id)
      .sort((a, b) => new Date(b.last_post.created_at).getTime() - new Date(a.last_post.created_at).getTime());

    return interactedSocieties?.reduce((acc, society) => acc + society.not_viewed_count, 0) || 0;
  }, [interactedSocietiesResponse]);

  return {
    candidateDashboard,
    candidateLastUpdatedStep,
    isCandidateLastStepLoading,
    totalUnreadPostCount,
    refetchCandidateDashboard,
    updateCandidatesLastStep,
  };
};

export type UseCandidateDashboardReturnType = ReturnType<typeof useCandidateDashboardInternalHook>;
