import { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { differenceInDays, formatDistanceToNowStrict, isAfter } from 'date-fns';
import cx from 'classnames';

import { Chip, ChipProps } from 'components/ComponentV2/Chip';
import Icon from 'components/Icon';
import { getFormattedDuration } from 'utils/format';

import styles from './ApplicationDeadline.module.scss';

type ApplicationDeadlineProps = {
  className?: string;
  date: string;
  size?: ChipProps['size'];
};

export const ApplicationDeadline: React.FC<ApplicationDeadlineProps> = memo(
  ({ className, date, size }) => {
    const [tc] = useTranslation('common');
    const diff = differenceInDays(new Date(date), new Date());
    const deadline =
      diff < 30 ? formatDistanceToNowStrict(new Date(date)) : null;
    const duration = deadline ? getFormattedDuration(deadline) : null;
    const isDeadlinePassed = useMemo(
      () => isAfter(new Date(), new Date(date)),
      [date]
    );

    const chipType: ChipProps['type'] = useMemo(() => {
      if (diff > 14 && diff <= 30) {
        return 'tertiary';
      } else if (diff > 7 && diff <= 14) {
        return 'warning';
      } else if (diff >= 1 && diff <= 7) {
        return 'orange';
      } else if (diff < 1) {
        return 'critical';
      }
    }, [diff]);

    if (!duration) return null;

    return (
      <Chip type={chipType} className={cx(styles.chip, className)} size={size}>
        <Icon iconName="icon_hourglass-outline" />
        {isDeadlinePassed
          ? tc('closed')
          : tc('opportunity.application-deadline-duration', { duration })}
      </Chip>
    );
  }
);

ApplicationDeadline.displayName = 'ApplicationDeadline';
