import { TPostParticipant, TStudentSociety } from './student-society';

export type TBasicUser = {
  id: string;
  type: 'basic_user';
  name: string;
  userable_id: string;
  userable_type: TUserableType;
  avatar_url: string | null;
  is_confirmed: boolean;
  is_huzzle_verified: boolean;
};

export type TBasicStudentSociety = Pick<
  TStudentSociety,
  | 'id'
  | 'name'
  | 'short_slug'
  | 'slug'
  | 'icon_url'
  | 'is_verified'
  | 'is_huzzle_verified'
  | 'subscribers_require_approval'
  | 'who_can_post'
  | 'who_can_reply_to_posts'
  | 'show_media_uploads_from_posts'
  | 'is_post_approval_required'
>;

export type TBasicCompany = {
  id: string;
  name: string;
  icon_url: string;
  is_verified: boolean;
  is_huzzle_verified: boolean;
};

export type TBasicStudentSocietyWithPostsStat = TBasicStudentSociety & {
  last_post: TPost;
  not_viewed_count: number;
};

export type TBasicStudentSocietyWithLastPost = TBasicStudentSociety & {
  last_post: TPost;
};

export enum TUserableType {
  Candidate = 'Candidate',
  Manager = 'Manager',
  Recruiter = 'Recruiter',
}

export enum TPostType {
  TextPost = 'TextPost',
  MediaPost = 'MediaPost',
  DocumentPost = 'DocumentPost',
  PollPost = 'PollPost',
}

export enum TPostState {
  Draft = 'draft',
  Approved = 'approved',
  Declined = 'declined',
}

export type TSocietyDiscussionsSettings = Pick<
  TStudentSociety,
  | 'who_can_post'
  | 'who_can_reply_to_posts'
  | 'show_media_uploads_from_posts'
  | 'is_post_approval_required'
>;

type TPostMedium = {
  id: string;
  url: string;
  file_name: string;
  record_id: string;
  record_type: string;
};

type TPostDocument = {
  id: string;
  url: string;
  file_name: string;
  record_id: string;
  record_type: string;
};

type TPostAnswer = {
  id: string;
  user_id: string;
  poll_answer_option_id: string;
  type: 'poll_answer';
};

type TPollAnswerOption = {
  id: string;
  value: string;
  poll_answers: Array<TPostAnswer>;
};

export type TPostLike = {
  id: string;
  type: 'like';
  user_id: string;
  userable_type: TUserableType;
};

type TTPollAnswerOptionPayload = {
  value: string;
};

export type PaginatedPostsResponse = {
  data: Array<TPost>;
  meta: {
    total: number;
  };
};

export type TPost = TTextPost | TMediaPost | TDocumentPost | TPollPost;

type TBasicPost = {
  id: string;
  created_at: string;
  updated_at: string;
  likes: Array<TPostLike>;
  parent_post_id?: string;
  comments: Array<Exclude<TPost, TDocumentPost>>;
  viewed_count: number;
  user: TBasicUser;
  posted_by_user_student_society?: TBasicStudentSociety;
  posted_by_user_company?: TBasicCompany;
  who_can_reply: TPostParticipant;
  state: TPostState;
  has_viewed: boolean;
  has_liked: boolean;
};

export type TTextPost = TBasicPost & {
  post_type: TPostType.TextPost;
  content: string;
};

export type TMediaPost = TBasicPost & {
  post_type: TPostType.MediaPost;
  media: Array<TPostMedium>;
  content: string | null;
};

export type PaginatedMediaAttachmentsResponse = {
  data: Array<TPostMedium>;
  meta: {
    total: number;
  };
};

export type PaginatedDocumentAttachmentsResponse = {
  data: Array<TPostDocument>;
  meta: {
    total: number;
  };
};

export type TDocumentPost = TBasicPost & {
  post_type: TPostType.DocumentPost;
  documents: Array<TPostDocument>;
  content: string | null;
};

export type TPollPost = TBasicPost & {
  post_type: TPostType.PollPost;
  poll_answer_options: Array<TPollAnswerOption>;
  content: string;
};

export type TPostPayload =
  | TTextPostPayload
  | TMediaPostPayload
  | TDocumentPostPayload
  | TPollPostPayload;

type TBasicPostPayload = {
  content: string;
  userable_type: TUserableType;
  parent_post_id?: string;
  who_can_reply: TSocietyDiscussionsSettings['who_can_reply_to_posts'];
};

export type TTextPostPayload = TBasicPostPayload & {
  type: TPostType.TextPost;
};

export type TMediaPostPayload = TBasicPostPayload & {
  type: TPostType.MediaPost;
  media: Array<File>;
};

export type TDocumentPostPayload = TBasicPostPayload & {
  type: TPostType.DocumentPost;
  documents: Array<File>;
};

export type TPollPostPayload = TBasicPostPayload & {
  type: TPostType.PollPost;
  poll_answer_options_attributes: Array<TTPollAnswerOptionPayload>;
};
