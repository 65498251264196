import React, { memo } from 'react';
import cn from 'classnames';

import styles from './Image.module.scss';

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  source?: 'aws';
}

export const Image: React.FC<ImageProps> = memo(
  ({ source, src, className, ...props }) => {
    const imageURL =
      source === 'aws'
        ? `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}/images/${src}`
        : src;

    return (
      <img
        className={cn(styles.Image, className)}
        src={imageURL}
        {...props}
        data-testid="Image"
      />
    );
  }
);

Image.displayName = 'Image';
