import React, { memo } from 'react';
import cn from 'classnames';

import { Link } from 'components';
import { Row } from 'components/layout';
import Salary from 'components/Salary';
import Deadline from 'components/Deadline';
import Chip from 'components/Chip';
import { TouchpointType } from 'lib/models/touchpoint';
import { WorkingModel } from 'lib/models/job';

import styles from './TouchpointCard.module.scss';

export interface TouchpointCardProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** title of the opportunity */
  title: string;
  application_deadline: string;
  maximum_salary?: number | null;
  minimum_salary?: number | null;
  pay_currency: string;
  touchpointable_type: TouchpointType;
  isExpired: boolean;
  /** public url for opportunity/company/society */
  url: string;
  kind: string;
  onClick?: () => void;
  cities: string;
  working_model: WorkingModel;
  area_of_responsibilities: string;
}

export const TouchpointCard: React.FC<TouchpointCardProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    title,
    maximum_salary,
    minimum_salary,
    pay_currency,
    application_deadline,
    touchpointable_type,
    isExpired,
    url,
    kind,
    onClick,
    cities,
    working_model,
    area_of_responsibilities,
  }: TouchpointCardProps) => {
    const contentClassNames = cn(
      {
        [styles.TouchpointCard]: true,
      },
      className
    );

    return (
      <Link
        href={url}
        onAnchorClick={onClick}
        underlineOnHover={false}
        className={styles.link}
      >
        <div
          className={contentClassNames}
          style={style}
          data-testid="TouchpointCard"
        >
          <p
            className={cn(styles.type, {
              [styles.job]: touchpointable_type === TouchpointType.Job,
              [styles.internship]:
                touchpointable_type === TouchpointType.Internship,
            })}
          >
            {touchpointable_type}
          </p>
          <p className={styles.title}>{title}</p>
          <Row className={styles.detailRow}>
            <p>
              {area_of_responsibilities}
              {cities ? (
                <>
                  <span className={styles.dot}>•</span>
                  {cities}
                </>
              ) : null}
            </p>
          </Row>
          {minimum_salary ||
          maximum_salary ||
          application_deadline ||
          kind ||
          working_model ? (
            <Row align="center" className={styles.detail}>
              {kind ? (
                <Chip className={styles.chip}>
                  {touchpointable_type === TouchpointType.Job
                    ? `💼 ${kind}`
                    : `🚀 ${kind}`}
                </Chip>
              ) : null}
              {minimum_salary || maximum_salary ? (
                <Salary
                  minimum_salary={minimum_salary}
                  maximum_salary={maximum_salary}
                  pay_currency={pay_currency}
                  className={cn(styles.salary, styles.chip)}
                />
              ) : null}
              {working_model === WorkingModel.REMOTE ? (
                <Chip className={styles.chip}>💻 {working_model}</Chip>
              ) : null}
              {!isExpired && application_deadline && (
                <Deadline
                  application_deadline={application_deadline}
                  className={styles.chipDeadline}
                />
              )}
            </Row>
          ) : null}
        </div>
      </Link>
    );
  }
);

TouchpointCard.displayName = 'TouchpointCard';
