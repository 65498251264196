import { add, compareAsc } from 'date-fns';
import format from 'date-fns/format';
import { sampleSize, shuffle } from 'lodash';

import { CohostAttributes } from 'lib/models/cohost';
import { TouchpointState } from 'lib/models/opportunity';
import { TagAttributes, TagType } from 'lib/models/tag';
import { TouchpointType } from 'lib/models/touchpoint';
import { parseResponse } from 'lib/utils/parser';
import { Option } from 'lib/models/option';

export const NUMBER_OF_COHOSTS = 7;
export const OTP_RESEND_TIMER = 30;

/** format number to k
 * e.g: 28000 -> 28k
 *      35500 -> 35.5k
 */
export function formatToK(input: number): string {
  if (input > 999) {
    const num = (input / 1000).toFixed(1).replace(/\.0$/, '');
    return `${String(num)}K`;
  } else {
    return String(input);
  }
}

export const getRandomInt = (
  min: number,
  max: number,
  returnNumber?: boolean
): string | number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  const number = Math.floor(Math.random() * (max - min) + min);
  if (returnNumber) return number;
  else return number < 10 ? `0${number}` : number;
  // The maximum is exclusive and the minimum is inclusive
};

export const convertToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result?.toString().split(',')[1] || '');
    reader.onerror = reject;
  });

export const getCardLabel = (type: string | undefined): string => {
  switch (type) {
    case TouchpointType.Job:
      return '💼 Job';
    case TouchpointType.Internship:
      return '🚀 Internship';
    case TouchpointType.Event:
      return '🎉 Event';
    case 'StudentSociety':
      return '🏛 Student Society';
    case 'Company':
      return '🏢 Company';
    default:
      return '';
  }
};

export const getPageTitle = (type: string | undefined): string => {
  switch (type) {
    case TouchpointType.Job:
      return 'Job';
    case TouchpointType.Internship:
      return 'Internship';
    case TouchpointType.Event:
      return 'Event';
    case 'StudentSociety':
      return 'Student Society';
    case 'Company':
      return 'Company';
    default:
      return '';
  }
};

export const getTouchpointBaseURLAndLabel = (
  type: TouchpointType
): { label: string; baseURL: string } => {
  let label = '',
    baseURL = '';
  switch (type) {
    case TouchpointType.Internship:
      label = 'internships';
      baseURL = '/internships';
      break;
    case TouchpointType.Event:
      label = 'events';
      baseURL = '/events';
      break;
    case TouchpointType.Job:
    default:
      label = 'jobs';
      baseURL = '/graduate-jobs';
  }

  return {
    label,
    baseURL,
  };
};

export const getFormattedDuration = (deadline: string): string => {
  return deadline
    .replace(' months', 'mo')
    .replace(' month', 'mo')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace(' hour', 'hr')
    .replace(' minutes', 'min')
    .replace(' minute', 'min');
};

export const getOpportunityIcon = (nested_type: string | undefined): string => {
  switch (nested_type) {
    case TouchpointType.Job:
      return '💼';
    case TouchpointType.Internship:
      return '🚀';
    case TouchpointType.Event:
      return '🎉';
    default:
      return '';
  }
};

export const getApplicationDeadlineInfo = (
  touchpointable_type: TouchpointType | undefined,
  application_deadline: string | undefined,
  start_date: string | undefined
): { info: string; detail: string } => {
  const label =
    touchpointable_type === TouchpointType.Event ? 'Register' : 'Apply';

  const applicationDeadline =
    application_deadline && format(new Date(application_deadline), 'MMM d');
  const startDate = start_date && format(new Date(start_date), 'MMM d');

  if (application_deadline)
    return {
      info: `${label} before ${applicationDeadline}`,
      detail: `The date mentioned here is the deadline for this ${touchpointable_type}. Make sure you ${label} before the deadline!`,
    };
  else if (start_date && touchpointable_type === TouchpointType.Event)
    return {
      info: `${label} before ${startDate}`,
      detail: `This event has no specific deadline stated. The date mentioned here is the start date of the event, apply as early as possible before the event starts.`,
    };
  else
    return {
      info:
        touchpointable_type === TouchpointType.Event
          ? 'Register early'
          : 'Rolling basis',
      detail:
        touchpointable_type === TouchpointType.Event
          ? `This event has no specified deadline or start date. It may be a pre-recorded event that you can always access or an event that has rolling registration, so register early before places fill up.`
          : `This ${touchpointable_type} has no specified deadline. The applications may be rolling, so apply as early as possible.`,
    };
};

// Notion documentation for the logic
// https://huzzlehq.notion.site/Updated-Event-states-Event-expiration-logic-7227438d4e8545b9beec26af8728a2b4
export const getEventState = (
  start_date: string | undefined,
  end_date: string,
  deadline: string | undefined,
  isApplied: boolean
): TouchpointState | null => {
  //compareAsc: Compare the two dates
  //  return 1 if the first date is after the second,
  //  return -1 if the first date is before the second
  //  return 0 if dates are equal.
  const deadlineToToday = deadline
    ? compareAsc(new Date(deadline), new Date())
    : null;
  const endDateToToday = end_date
    ? compareAsc(new Date(end_date), new Date())
    : start_date
    ? compareAsc(add(new Date(start_date), { hours: 1 }), new Date())
    : null;

  if (endDateToToday === -1) return TouchpointState.ENDED;
  else if (deadlineToToday === -1 && !isApplied && endDateToToday === 1)
    return TouchpointState.CLOSED;
  else return null;
};

export const isEventLive = (start_date: string, end_date: string): boolean => {
  const endDateToToday = end_date
    ? compareAsc(new Date(end_date), new Date())
    : start_date
    ? compareAsc(add(new Date(start_date), { hours: 1 }), new Date())
    : null;
  const startDateToToday = start_date
    ? compareAsc(new Date(start_date), new Date())
    : null;

  if (startDateToToday === -1 && endDateToToday === 1) return true;
  else return false;
};

export const getEventButtonText = (
  eventState: TouchpointState | null,
  enforce_capacity: boolean,
  capacity: number,
  seatsAvailable: number | undefined,
  waitlist: boolean,
  enforce_waitlist_for_all: boolean
): string => {
  if (eventState === TouchpointState.ENDED) {
    return 'Ended';
  } else if (eventState === TouchpointState.CLOSED) {
    return 'Closed';
  } else if (
    waitlist &&
    (enforce_waitlist_for_all ||
      (enforce_capacity && seatsAvailable !== undefined && seatsAvailable <= 0))
  ) {
    return 'Join Waitlist';
  } else if (
    enforce_capacity &&
    capacity &&
    seatsAvailable !== undefined &&
    seatsAvailable <= 0
  ) {
    return TouchpointState.SOLD_OUT;
  } else return 'Register';
};

export const isPastDate = (date: Date, timezone?: string): boolean => {
  const now = new Date(
    new Date().toLocaleString('en-US', { timeZone: timezone })
  );
  const currentDateInTimezone = new Date(now);
  return date < currentDateInTimezone;
};

export const dateIsValid = (date: Date): boolean => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (!isNaN(date.getTime())) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getEventAppliedWaitlistedLabel = (
  isApplied: boolean,
  isWaitlisted: boolean
): string => {
  if (isWaitlisted) {
    return 'You’re on the Waitlist';
  } else if (isApplied) {
    return 'Registered';
  } else return '';
};

export const getRatingAverage = (
  rating_map: Record<string, number>
): number => {
  let totalLength = 0;
  let totalCount = 0;
  Object.keys(rating_map || {}).forEach((key) => {
    totalLength += Number(rating_map[key]);
    totalCount += Number(key) * Number(rating_map[key]);
  });
  return totalLength > 0 && totalCount > 0
    ? Number((totalCount / totalLength).toFixed(1))
    : 0;
};

export const formatDate = (date: Date) => {
  if (dateIsValid(date)) {
    return format(date, 'MMM yyyy');
  }
  return '';
};

export const formatDateToSave = (date: Date): string => {
  return String(date).split('G')[0] || String(date);
};

export const formatDateToRetrieve = (date: string): Date => {
  return new Date(date.split('+')[0]);
};

export const getFormattedFilename = (title: string): string => {
  let refactoredTitle = '';
  if (title == 'subscribed') {
    refactoredTitle = 'joined';
  } else if (title == 'subscription_requests') {
    refactoredTitle = 'membership_request';
  } else if (title == 'unsubscribed') {
    refactoredTitle = 'past_members';
  }

  const i = refactoredTitle
    ? refactoredTitle.replace(/[^A-Za-z0-9_]/g, '-').replace(/-+/g, '-')
    : '';
  return i ? i.substr(0, 10) : '';
};

export const randomBGColor = [
  '#FEECEE',
  '#FFEEE2',
  '#FDF7DB',
  '#F4FFE7',
  '#ECFEF4',
  '#EBFFFF',
  '#EDF7FF',
  '#F0EDFF',
  '#FCEDFF',
  '#FFEDF6',
];
export const randomEmoji = [
  '👨🏻',
  '🧑🏾‍🦰',
  '👱🏼‍♀️',
  '👨🏽‍🦱',
  '👨🏼‍🦲',
  '🧕🏽',
  '👩🏼‍🦳',
  '👨🏿‍🦲',
  '👨🏼‍🦰',
  '👩🏾',
  '👱🏽',
  '👱🏻‍♂️',
  '🧔🏻',
  '👩🏾‍🦱',
  '👩🏽‍🦰',
  '🧑🏻‍🦱',
  '🧔🏾',
  '🧑🏻‍🦰',
  '🧑🏾‍🦳',
  '👨🏼',
];

export const getRandomProfiles = (
  maxPicsToShow: number,
  urls: Array<string>
): Array<string> => {
  if (urls && urls.length >= maxPicsToShow) {
    const urlsToShow = sampleSize(urls, maxPicsToShow);
    return urlsToShow;
  } else {
    const urlsToShow = sampleSize(urls, maxPicsToShow);
    const noOfRandomProfilesToShow = maxPicsToShow - urlsToShow.length;
    const emojisToShow = sampleSize(randomEmoji, noOfRandomProfilesToShow);
    const profilePics = [...urlsToShow, ...emojisToShow];
    return shuffle(profilePics);
  }
};

export const getCommaSeparatedNumber = (value: string): string => {
  return value.replace(/,/gi, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getNumberOfWords = (text: string): number => {
  const words = text.trim().split(/\s+/);
  return words.length;
};

export const getIndustryName = (
  custom_industry_name: string,
  industryInfo: TagAttributes<TagType.Industry>
): string => {
  if (custom_industry_name) return custom_industry_name;
  else if (industryInfo) return industryInfo.name;
  else return '';
};

export const CANDIDATE_HEADER_HEIGHT = 72;

export const getHostNames = ({
  createdBy,
  cohost_applications,
}: {
  createdBy: string;
  cohost_applications: Array<CohostAttributes> | null;
}): string => {
  if (cohost_applications && cohost_applications.length > 0) {
    const cohost = cohost_applications?.map(({ cohost: cohostResponse }) => {
      const cohost = parseResponse(cohostResponse);
      const { name } = cohost;
      return { name };
    });
    const cohostNames =
      cohost &&
      cohost.length !== 0 &&
      (cohost.length === 1
        ? ` and ${cohost[0].name}`
        : `, ${cohost[0].name} and ${cohost.length - 1} ${
            cohost.length - 1 === 1 ? `other` : `others`
          }`);

    return `${createdBy}${cohostNames}`;
  } else return createdBy;
};

export const getHostLogos = ({
  cohost_applications,
}: {
  cohost_applications: Array<CohostAttributes>;
}): {
  icon_url: string;
  is_verified: boolean;
  huzzle_verified: boolean;
  initials: string;
} | null => {
  if (cohost_applications && cohost_applications.length > 0) {
    const cohost = cohost_applications?.map(({ cohost: cohostResponse }) => {
      const cohost = parseResponse(cohostResponse);
      const { icon_url, is_verified, huzzle_verified, name } = cohost;
      const initials = name ? name.split(' ')[0] : '';

      return { icon_url, is_verified, huzzle_verified, initials };
    });
    return cohost[0];
  } else return null;
};

export const numberToOrdinal = (number: number): string => {
  if (typeof number !== 'number') {
    throw new Error('Input must be a number');
  }

  // Special case for numbers ending in 11, 12, and 13
  if (number % 100 >= 11 && number % 100 <= 13) {
    return number + 'th';
  }

  // Handle other cases
  switch (number % 10) {
    case 1:
      return number + 'st';
    case 2:
      return number + 'nd';
    case 3:
      return number + 'rd';
    default:
      return number + 'th';
  }
};

export const isValueInBoundary = <T extends number | string>(
  value: T,
  lowerBound: T,
  upperBound: T
): boolean => {
  if (
    typeof value === 'number' &&
    typeof lowerBound === 'number' &&
    typeof upperBound === 'number'
  ) {
    return value >= lowerBound && value <= upperBound;
  }
  return false;
};

export const getCurrency = (pay_currency: string) => {
  switch (pay_currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      return '£';
  }
};

export const durationType = (duration_type: string | undefined) => {
  if (duration_type == 'per_month') return '/month';
  else if (duration_type == 'per_year') return '/year';
  else if (duration_type == 'one_time') return 'one time';
  else return duration_type?.replace('_', ' ');
};

export const convertBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const sortedCountriesList = (countries: Array<Option> | undefined) => {
  if (!countries) return;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let firstCountry: Option | undefined;
  if (timezone.includes('America')) {
    firstCountry = countries.find(
      (country) => country?.label === 'United States'
    );
  } else {
    firstCountry = countries.find(
      (country) => country?.label === 'United Kingdom'
    );
  }
  if (firstCountry) {
    const newList = countries.filter((item) => item.id !== firstCountry?.id);
    newList.unshift(firstCountry);
    return newList;
  } else return countries;
};

export const replaceLineBreaksWithBullet = (value: string): string => {
  const replaceNewLinePattern = /\n(?!• )/g;
  let bulletsList = value.replace(replaceNewLinePattern, '\n• ');
  if (bulletsList.includes('\n• \n•')) {
    bulletsList = bulletsList.replaceAll('\n• \n• ', '\n\n• ');
  }
  return bulletsList;
};
