import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { setAuthHeaders } from 'lib/utils/axios';

export interface UserSecret {
  email: string;
  token: string;
}

const { persistAtom } = recoilPersist({
  key: 'user',
  storage: process.browser ? localStorage : undefined,
});

export const userSecretState = atom<UserSecret | null>({
  key: 'userSecret',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const httpHeadersState = selector({
  key: 'httpHeaders',
  get: ({ get }) => {
    const userSecret = get(userSecretState);
    const headers: HeadersInit = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const plainHeaders: HeadersInit = new Headers();
    if (userSecret) {
      const { email, token } = userSecret;
      headers.set('X-Auth-Email', email);
      headers.set('X-Auth-Token', token);
      plainHeaders.set('X-Auth-Email', email);
      plainHeaders.set('X-Auth-Token', token);
    }
    setAuthHeaders(userSecret);
    return { headers, plainHeaders };
  },
});
