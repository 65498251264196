import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  UseCommonListReturnType,
  useCommonList as useCommonListInternal,
} from 'hooks/useCommonList';

const initialContext: UseCommonListReturnType = {
  areaOfResponsibilities: [],
  opportunityTypes: undefined,
  internshipKinds: undefined,
  internshipKindOptions: undefined,
  eventKinds: undefined,
  roleLevels: undefined,
  educationRequirementsOptions: [],
  currencyOptions: undefined,
  eventCategoriesList: [],
  eventCategoriesOptions: [],
  industryOptions: [],
  topicOptions: [],
  topicTypeOptions: [],
  eventTypeOptions: [],
  eventModeOptions: [],
  companySizeOptions: [],
  employeesCountRange: undefined,
  universities: [],
  subjects: [],
  degrees: [],
  studyYearOptions: [],
  countries: undefined,
  cityCountryList: [],
  genders: [],
  physicalDisability: [],
  congnitiveDisability: [],
  socioEconomicStatus: [],
  raceEthnicity: [],
  offeringOptions: [],
  workTypeList: [],
  workModeList: [],
  datePostedList: [],
  languageOptions: [],
  defaultLanguage: '',
  cities: [],
  salaryOptions: [],
  experienceLevels: [],
  industryTypeOptions: [],
  graduateDateOptions: [],
  verifiedSocietiesOptions: undefined,
  isSocietiesDataLoading: false,
  fetchMoreSocieties: noop,
  setSocietyFilterParams: noop,
  setSocietyIds: noop,
  societyIdsData: [],
  universitiesOptions: undefined,
  isUniversitiesDataLoading: false,
  fetchMoreUniversities: noop,
  setUniversityFilterParams: noop,
  setUniversityId: noop,
  universityIdData: [],
  disabilityFilterOptions: [],
  socioEconomicStatusFilterOptions: [],
  configData: { jobs_count: '', opportunity_count: '', company_count: '' },
  isConfigDataLoading: true,
};

const CommonListContext =
  createContext<UseCommonListReturnType>(initialContext);

export const useCommonList = (): UseCommonListReturnType =>
  useContext(CommonListContext);

export const CommonListProvider: React.FC = ({ children }) => {
  const value = useCommonListInternal();

  return (
    <CommonListContext.Provider value={value}>
      {children}
    </CommonListContext.Provider>
  );
};
