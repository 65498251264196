import { useEffect } from 'react';

import { useAuth } from 'lib/providers/AuthProvider';

const isProduction =
  process.env.NEXT_PUBLIC_TARGET_ENVIRONMENT === 'production';

export const useCrazyEgg = () => {
  const { user } = useAuth();
  const userID = user?.id;

  useEffect(() => {
    if (!userID || !isProduction) return;

    // Crazy Egg Script is already loaded
    // @ts-ignore
    if (window.CE2) {
      // @ts-ignore
      CE2.identify?.(userID);
    } else {
      // if not loaded yet, set the recording identifier once the script loads
      // @ts-ignore
      (window.CE_API || (window.CE_API = [])).push(function () {
        // @ts-ignore
        CE2.identify?.(userID);
      });
    }
  }, [userID]);
};
