import { CitiesResponse } from './city';
import { TagsResponse, TagType } from './tag';
import { TouchpointType } from './touchpoint';
import { QuestionsResponse, QuestionState } from './answers';
import { CompanyAttributes } from './company';

export enum WorkingModel {
  REMOTE = 'remote',
  HYBRID = 'hybrid',
  OFFICE = 'office',
}

export enum JobPostingState {
  DRAFT = 'draft',
  SUBMIT_FOR_APPROVAL = 'ready_for_approval',
  PUBLISHED = 'published',
}

export interface JobTouchpointable {
  data: {
    id: string;
    type: 'job' | 'internship';
    attributes: {
      id: string;
      start_date: string;
      end_date: string;
      kind: string;
      minimum_salary: number | null;
      maximum_salary: number | null;
      full_time: boolean;
      working_model: WorkingModel;
      apply_url: string;
      icon_url: string;
    };
  };
}

export interface JobAttributes {
  id: string;
  slug: string;
  title: string;
  application_deadline: string;
  description: string;
  cities: CitiesResponse;
  area_of_responsibilities: TagsResponse<TagType.AreaOfResponsibility>;
  responsibilities: Array<string>;
  requirements: Array<string>;
  education_requirements: TagsResponse<TagType.EducationRequirement>;
  application_process: Array<string>;
  apply_url: string;
  touchpointable: JobTouchpointable;
  enabled: boolean;
  touchpointable_type: TouchpointType.Job | TouchpointType.Internship;
  kind: string;
  compensation_details: string;
  pay_currency: string;
  state: JobPostingState;
  linkedin_profile_required_state: QuestionState;
  cv_required_state: QuestionState;
  questions: QuestionsResponse;
  is_online: boolean;
  notify_creatable?: boolean;
  created_at: string;
  start_date?: Date;
  expiry_reason?: string;
  featured: boolean;
  creatable_for: {
    data: { id: string; type: string; attributes: CompanyAttributes };
  };
}

export interface Job {
  id: string;
  type: 'touchpoint';
  attributes: JobAttributes;
}

export interface JobResponse {
  data: Job;
}

export interface JobRequestStep1 {
  title: string;
  touchpointable_attributes: {
    id: string;
    type: TouchpointType;
    kind: string | undefined;
    working_model: WorkingModel;
  };
  area_of_responsibility_ids: Array<string>;
  city_ids: Array<string>;
  is_online: boolean;
  submitType?: string;
}

export interface JobRequestStep2 {
  touchpointable_attributes: {
    id: string;
    full_time: boolean;
    minimum_salary: string | null;
    maximum_salary: string | null;
    start_date: Date | null | string;
    end_date: Date | string | null;
  };
  pay_currency: string | null;
  compensation_details: string | null;
  responsibilities: Array<string>;
  requirements: Array<string>;
  education_requirement_ids: Array<string>;
  submitType?: string;
}

export interface JobRequestStep3 {
  application_deadline: Date | null | string;
  application_process: Array<string>;
  cv_required_state: QuestionState;
  linkedin_profile_required_state: QuestionState;
  state: JobPostingState;
  submitType?: string;
  questions_attributes?: Array<{
    id?: string;
    title: string;
    state: QuestionState;
    isNew?: boolean;
    options?: Array<string>;
    questionType?: string;
  }>;
  removedCustomQuestionIds?: Array<string>;
}
