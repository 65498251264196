import { setExtra, captureException } from '@sentry/browser';
import {
  TCandidate,
  TManager,
  TRecruiter,
  TUserApiAttributes,
  TUserApiResponse,
  TUserableType,
  UserAvatarFields,
} from 'lib/models/User.model';
import { CompanyResponse, TCompany } from 'lib/models/company';
import {
  StudentSocietyResponse,
  TStudentSociety,
} from 'lib/models/student-society';
import { parseResponse } from 'lib/utils/parser';

export function isCandidateResponse(userResponse: TUserApiResponse) {
  if (!userResponse) {
    return false;
  }
  const userAttributes = parseResponse(userResponse);
  return isCandidateUserable(userAttributes);
}

export function isCandidateUserable(
  userApiAttributes: TUserApiAttributes
): userApiAttributes is TCandidate {
  try {
    if (!userApiAttributes) {
      return false;
    }

    const { userable_type } = parseResponse(userApiAttributes?.user);
    return userable_type === TUserableType.CANDIDATE;
  } catch (error) {
    setExtra('userApiAttributes', JSON.stringify(userApiAttributes));
    captureException(error);
    return false;
  }
}

export function isManagerUserable(
  userApiAttributes: TUserApiAttributes
): userApiAttributes is TManager {
  if (!userApiAttributes) {
    return false;
  }
  const { userable_type } = parseResponse(userApiAttributes.user);
  return userable_type === TUserableType.MANAGER;
}

export function isRecruiterUserable(
  userApiAttributes: TUserApiAttributes
): userApiAttributes is TRecruiter {
  if (!userApiAttributes) {
    return false;
  }
  const { userable_type } = parseResponse(userApiAttributes.user);
  return userable_type === TUserableType.RECRUITER;
}

export function getUserAvatarFields({
  candidate,
  society,
  company,
}: {
  candidate?: TCandidate | null;
  society?: TStudentSociety | null;
  company?: TCompany | null;
}): UserAvatarFields | null {
  if (candidate) {
    return {
      id: candidate.id,
      team_member_id: null,
      name: candidate.name,
      userable_type: TUserableType.CANDIDATE,
      image_url: candidate.avatar_url,
      is_verified: false,
      is_huzzle_verified: candidate.huzzle_verified,
    };
  } else if (society) {
    return {
      id: society.id,
      team_member_id: null,
      name: society.name,
      userable_type: TUserableType.MANAGER,
      image_url: society.icon_url,
      is_verified: society.is_verified,
      is_huzzle_verified: society.is_huzzle_verified,
    };
  } else if (company) {
    return {
      id: company.id,
      team_member_id: null,
      name: company.name,
      userable_type: TUserableType.RECRUITER,
      image_url: company.icon_url,
      is_verified: company.is_verified,
      is_huzzle_verified: company.huzzle_verified,
    };
  }

  return null;
}

export function isCompanyResponse(
  response: CompanyResponse | StudentSocietyResponse
): response is CompanyResponse {
  return response.data.type === 'company';
}

export function isStudentSocietyResponse(
  response: CompanyResponse | StudentSocietyResponse
): response is StudentSocietyResponse {
  return response.data.type === 'student_society';
}
