import cx from 'classnames';

import styles from './Badge.module.scss';

type BadgeProps = {
  children: React.ReactNode;
  className?: string;
};

const Badge: React.FC<BadgeProps> = ({ children, className }) => {
  return <div className={cx(styles.badge, className)}>{children}</div>;
};

Badge.displayName = 'Badge';

export default Badge;
