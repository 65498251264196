import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  UseCandidateDashboardReturnType,
  useCandidateDashboardInternalHook,
} from 'hooks/useCandidateDashboard/useCandidateDashboardInternal';

const initialContext: UseCandidateDashboardReturnType = {
  candidateDashboard: null,
  candidateLastUpdatedStep: null,
  isCandidateLastStepLoading: false,
  totalUnreadPostCount: 0,
  refetchCandidateDashboard: async () => void noop(),
  updateCandidatesLastStep: async () => void noop(),
};

const CandidateDashboardContext =
  createContext<UseCandidateDashboardReturnType>(initialContext);

export const useCandidateDashboard = () =>
  useContext(CandidateDashboardContext);

export const CandidateDashboardProvider: React.FC = ({ children }) => {
  const value = useCandidateDashboardInternalHook();

  return (
    <CandidateDashboardContext.Provider value={value}>
      {children}
    </CandidateDashboardContext.Provider>
  );
};
