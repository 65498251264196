import { ReactElement, useEffect, useRef, useState } from 'react';

export const useDynamicSVGImport = (
  name: string
): { SVGIcon: (() => ReactElement) | null; loading: boolean } => {
  const importedIconRef = useRef(null);
  const mountedRef = useRef<boolean>(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!name) return;
    const importIcon = async () => {
      try {
        setLoading(true);
        const icon = await import(`!svg-react-loader!./svgs/${name}.svg`);
        if (mountedRef.current === true) {
          importedIconRef.current = icon.default;
          setLoading(false);
        }
      } catch (err) {
        if (mountedRef.current === true) {
          setLoading(false);
        }
        console.error(err);
      }
    };
    importIcon();
  }, [name]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return { SVGIcon: importedIconRef.current, loading };
};
