const { NEXT_PUBLIC_API_HOST_URL } = process.env;

const defaultHeaders: HeadersInit = new Headers({
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

export async function http<T>(path: string, args: RequestInit): Promise<T> {
  const request = new Request(`${NEXT_PUBLIC_API_HOST_URL}${path}`, args);
  const response = await fetch(request);
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.alert || error.error);
  }

  return await response.json();
}

export async function get<T>(
  path: string,
  headers: HeadersInit = defaultHeaders,
  args: RequestInit = { method: 'GET', headers }
): Promise<T> {
  // @ts-ignore
  if (path instanceof Array) {
    // @ts-ignore
    const [url, hds = defaultHeaders] = path;
    const argss = { method: 'GET', headers: hds };
    return get(url, hds, argss);
  } else {
    return await http<T>(path, args);
  }
}

export async function remove<T>(
  path: string,
  headers: HeadersInit = defaultHeaders,
  args: RequestInit = { method: 'DELETE', headers }
): Promise<T> {
  return await http<T>(path, args);
}

export async function post<T>(
  path: string,
  body: Record<string, unknown> | Record<string, unknown>[],
  headers: HeadersInit = defaultHeaders,
  args: RequestInit = { method: 'POST', body: JSON.stringify(body), headers }
): Promise<T> {
  return await http<T>(path, args);
}

export async function put<T>(
  path: string,
  body: Record<string, unknown> | Record<string, unknown>[],
  headers: HeadersInit = defaultHeaders,
  args: RequestInit = { method: 'PUT', body: JSON.stringify(body), headers }
): Promise<T> {
  return await http<T>(path, args);
}

export async function patch<T>(
  path: string,
  body: Record<string, unknown> | Record<string, unknown>[],
  headers: HeadersInit = defaultHeaders,
  args: RequestInit = { method: 'PATCH', body: JSON.stringify(body), headers }
): Promise<T> {
  return await http<T>(path, args);
}
