import React, { memo } from 'react';

import { Chip, ChipProps } from 'components/ComponentV2/Chip';
import { formatToK, getCurrency } from 'utils/format';

export type OpportunitySalaryProps = {
  className?: string;
  maximumSalary: number | null;
  minimumSalary: number | null;
  payCurrency?: string;
  isEstimated?: boolean;
  isTertiaryType?: boolean;
  size?: ChipProps['size'];
};

export const OpportunitySalary: React.FC<OpportunitySalaryProps> = memo(
  ({
    className = '',
    maximumSalary,
    minimumSalary,
    payCurrency,
    isEstimated,
    isTertiaryType,
    size,
  }: OpportunitySalaryProps) => {
    if (minimumSalary === null && maximumSalary === null) {
      return null;
    }

    if (minimumSalary === maximumSalary && minimumSalary !== null) {
      return (
        <Chip
          className={className}
          type={
            isTertiaryType ? 'tertiary' : isEstimated ? 'warning' : 'primary'
          }
          size={size}
        >
          {`${getCurrency(payCurrency || '')}${formatToK(minimumSalary)}`}
        </Chip>
      );
    }

    return (
      <Chip
        className={className}
        type={isTertiaryType ? 'tertiary' : isEstimated ? 'warning' : 'primary'}
        size={size}
      >
        {minimumSalary !== null
          ? `${getCurrency(payCurrency || '')}${formatToK(minimumSalary)}`
          : null}
        {minimumSalary !== null && maximumSalary !== null ? ` - ` : null}
        {maximumSalary !== null
          ? `${getCurrency(payCurrency || '')}${formatToK(maximumSalary)}`
          : null}
      </Chip>
    );
  }
);

OpportunitySalary.displayName = 'OpportunitySalary';
