import React, { memo } from 'react';
import cn from 'classnames';

import type { SelectionProps } from '../Selection';
import Selection from '../Selection';

import styles from './Radio.module.scss';

export type RadioProps = Omit<SelectionProps, 'type'>;

export const Radio: React.FC<RadioProps> = memo(
  ({
    className = '', // custom class name
    style,
    labelFirst = false,
    ...props
  }: RadioProps) => {
    const contentClassNames = cn(styles.Radio, className);

    return (
      <Selection
        {...props}
        className={contentClassNames}
        style={style}
        labelFirst={labelFirst}
        type="radio"
        dataTestid="Checkbox"
      />
    );
  }
);

Radio.displayName = 'Radio';
