import React from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import { RowContext } from 'components/layout/Row';
import { Breakpoint } from 'lib/utils/responsiveObserve';

import styles from './Col.module.scss';

type FlexType = number | 'none' | 'auto' | string;
type AlignType = 'start' | 'center' | 'end' | 'stretch';

export interface ColSize {
  flex?: number | string;
  span?: number;
  order?: number;
  offset?: number;
  push?: number;
  pull?: number;
  align?: AlignType;
}

export interface ColProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** children */
  children?: React.ReactNode;
  /** Flex layout style */
  flex?: FlexType;
  /** Vertical alignment */
  align?: AlignType;
  /** The number of cells to offset Col from the left */
  offset?: number;
  /** The flex order of the column */
  order?: number;
  /** Number of cells that raster is moved to the left */
  pull?: number;
  /** Number of cells that raster is moved to the right */
  push?: number;
  /** Number of cells to occupy, 0 corresponds to display: none */
  span?: number;
  /** screen <= 414px and also default setting, could be a span value or an object containing above props */
  xs?: number | ColSize;
  /** screen <= 767px, could be a span value or an object containing above props */
  sm?: number | ColSize;
  /** screen < 1280px, could be a span value or an object containing above props */
  md?: number | ColSize;
  /** screen ≥ 1280px, could be a span value or an object containing above props */
  lg?: number | ColSize;
  /** screen ≥ 1440px, could be a span value or an object containing above props */
  xl?: number | ColSize;
  /** screen ≥ 1800px, could be a span value or an object containing above props */
  xxl?: number | ColSize;
  sm_desktop_down?: number | ColSize;
  xs_up?: number | ColSize;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Col: React.FC<ColProps> = ({
  className = '', // custom class name
  style, // custom style
  span,
  flex,
  align,
  order,
  offset,
  pull,
  push,
  onClick,
  children,
  ...props
}: ColProps) => {
  const { gutter } = React.useContext(RowContext);
  const sizes: Breakpoint[] = [
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'xxl',
    'sm_desktop_down',
    'xs_up',
  ];

  function parseFlex(flex: FlexType): string {
    if (typeof flex === 'number') {
      return `${flex} ${flex} auto`;
    }

    if (/^\d+(\.\d+)?(px|%)$/.test(flex)) {
      return `0 0 ${flex}`;
    }

    return flex;
  }

  let sizeClasses = '';
  sizes.forEach((size) => {
    let sizeProps: ColSize = {};
    const propSize = props[size];
    if (typeof propSize === 'number') {
      sizeProps.span = propSize;
    } else if (typeof propSize === 'object') {
      sizeProps = propSize || {};
    }
    const capitalSize = size.toUpperCase();
    sizeClasses = cn(
      {
        [`${styles[`ColSpan${capitalSize}${sizeProps.span}`]}`]:
          sizeProps.span !== undefined,
        [`${styles[`ColOrder${capitalSize}${sizeProps.order}`]}`]:
          sizeProps.order || sizeProps.order === 0,
        [`${styles[`ColOffset${capitalSize}${sizeProps.offset}`]}`]:
          sizeProps.offset || sizeProps.offset === 0,
        [`${styles[`ColPull${capitalSize}${sizeProps.pull}`]}`]:
          sizeProps.pull || sizeProps.pull === 0,
        [`${styles[`ColPush${capitalSize}${sizeProps.push}`]}`]:
          sizeProps.push || sizeProps.push === 0,
        [`${styles[`ColAlign${capitalSize}${capitalize(sizeProps.align)}`]}`]:
          !!sizeProps.align,
      },
      sizeClasses
    );
  });

  const contentClassNames = cn(
    {
      [styles.Col]: true,
      [`${styles[`ColSpan${span}`]}`]: span !== undefined,
      [`${styles[`ColOrder${order}`]}`]: order,
      [`${styles[`ColOffset${offset}`]}`]: offset,
      [`${styles[`ColPull${pull}`]}`]: pull,
      [`${styles[`ColPush${push}`]}`]: push,
      [`${styles[`ColAlign${capitalize(align)}`]}`]: align,
    },
    className,
    sizeClasses
  );

  let mergedStyle: React.CSSProperties = { ...style };
  // Horizontal gutter use padding
  if (gutter && gutter[0] > 0) {
    const horizontalGutter = gutter[0] / 2;
    mergedStyle.paddingLeft = horizontalGutter;
    mergedStyle.paddingRight = horizontalGutter;
  }

  // Vertical gutter use padding when gap not support
  if (gutter && gutter[1] > 0) {
    const verticalGutter = gutter[1] / 2;
    mergedStyle.paddingTop = verticalGutter;
    mergedStyle.paddingBottom = verticalGutter;
  }

  if (flex) {
    mergedStyle = { ...mergedStyle, flex: parseFlex(flex) };
  }

  return (
    <div
      className={contentClassNames}
      style={mergedStyle}
      data-testid="Col"
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
};
