import React from 'react';
import { captureException } from '@sentry/nextjs';

import { Image } from 'components';
import { Button } from 'components/form';

import styles from './ErrorBoundary.module.scss';

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component {
  state: State = { hasError: false };

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    captureException(error);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <section className={styles.container} data-testid="NotFound">
          <div className={styles.content}>
            <div className={styles.image}>
              <Image src="oops.png" source="aws" alt="Something went wrong" />
            </div>
            <p className={styles.title}>Ooops! Something went wrong!</p>
            <p className={styles.description}>Please refresh and try again.</p>
            <Button
              variant="contained"
              color="primary"
              className={styles.refreshButton}
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
