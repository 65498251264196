import React, { ReactElement } from 'react';
import type { AppLayoutProps } from 'next/app';
import Head from 'next/head';
import { RecoilRoot } from 'recoil';
import ModalContainer from 'react-modal-promise';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';

import NotificationToaster from 'components/NotificationToaster';
import ErrorBoundary from 'components/ErrorBoundary';
import FacebookPixel from 'components/FacebookPixel';
import GTM from 'components/GTM';
import HeapScript from 'components/HeapScript';
import GlobalScriptAction from 'components/GlobalScriptAction';
import { SaveInAppProvider } from 'lib/contexts/save-in-app-context';
import { CampaignsProvider } from 'lib/contexts/campaigns-context';
import { SocietiesSearchProvider } from 'lib/contexts/societies-search-context';
import { SocietyGlobalContextProvider } from 'lib/contexts/society-global';

import { ManagerFiltersContextProvider } from 'lib/contexts/manager-filters-context';
import { CommonListProvider } from 'lib/contexts/common-list-context';
import { AuthProvider } from 'lib/providers/AuthProvider';
import { CandidateMatchesProvider } from 'lib/providers/CandidateMatchesProvider';
import { CandidateDashboardProvider } from 'lib/providers/CandidateDashboardProvider';
import nextI18NextConfig from '../../next-i18next.config.js';

import 'styles/globals.css';
import 'styles/theme.scss';

const isProduction =
  process.env.NEXT_PUBLIC_TARGET_ENVIRONMENT === 'production';

function MyApp({ Component, pageProps }: AppLayoutProps): ReactElement {
  const getLayout = Component.getLayout || ((page: React.ReactNode) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {isProduction && (
          <meta
            name="google-site-verification"
            content="sZf2-yH8YTxOJcOfqGl6X-ENdcU_bjTztM9wcHV1UWk"
          />
        )}
        {/* HubSpot Embed Code */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/143225811.js"
        ></script>
      </Head>
      <HeapScript />
      <FacebookPixel />
      <GTM />
      <ErrorBoundary>
        <RecoilRoot>
          <AuthProvider>
            <SessionProvider session={pageProps.session}>
              <CommonListProvider>
                <CampaignsProvider>
                  {/* Added SocietiesSearchProvider to show cohosts in society and company events AddCohostModal that is used at multiple places */}
                  <SocietiesSearchProvider>
                    <SocietyGlobalContextProvider>
                      <ManagerFiltersContextProvider>
                        <SaveInAppProvider>
                          <CandidateMatchesProvider>
                            <CandidateDashboardProvider>
                              {getLayout(<Component {...pageProps} />)}
                              <NotificationToaster />
                              <GlobalScriptAction />
                              <ModalContainer />
                            </CandidateDashboardProvider>
                          </CandidateMatchesProvider>
                        </SaveInAppProvider>
                      </ManagerFiltersContextProvider>
                    </SocietyGlobalContextProvider>
                  </SocietiesSearchProvider>
                </CampaignsProvider>
              </CommonListProvider>
            </SessionProvider>
          </AuthProvider>
        </RecoilRoot>
      </ErrorBoundary>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
