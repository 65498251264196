import { Fragment, memo } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';

import { Image } from 'components';
import { REGEX } from 'lib/utils/regex';
import { getRandomInt, getRandomProfiles, randomBGColor } from 'utils/format';
import { isSafari } from 'utils/dom';

import styles from './EventApplicants.module.scss';

type EventApplicantsProps = {
  className?: string;
  application_count: number;
  avatar_urls: string[];
};

const MAX_APPLICANTS_COUNT = 10;

export const EventApplicants: React.FC<EventApplicantsProps> = memo(
  ({ application_count, avatar_urls, className }) => {
    if (application_count === 0) return null;

    const [tc] = useTranslation('common');
    const remainingApplicantsCount = application_count - MAX_APPLICANTS_COUNT;
    const profilePics = getRandomProfiles(
      Math.min(MAX_APPLICANTS_COUNT, application_count),
      avatar_urls || []
    );

    const getEmoji = (emoji: string) => {
      const randomNumber = getRandomInt(0, MAX_APPLICANTS_COUNT, true);
      const color = randomBGColor[randomNumber as number];
      return (
        <div className={styles['image-container']}>
          <p
            style={{ backgroundColor: color }}
            className={cx(styles.emoji, { [styles['is-safari']]: isSafari })}
          >
            {emoji}
          </p>
        </div>
      );
    };

    const getProfilePic = (url: string) => {
      return (
        <div className={styles['image-container']}>
          <Image width={27} height={27} src={url} className={styles.image} />
        </div>
      );
    };

    return (
      <div className={cx(styles.container, className)}>
        <div className={styles.images}>
          {profilePics.map((item, index) => (
            <Fragment key={index}>
              {REGEX.URL_CHECK.test(item)
                ? getProfilePic(item)
                : getEmoji(item)}
            </Fragment>
          ))}
          {remainingApplicantsCount > 0 ? (
            <div className={styles['more-applicants']}>
              <p className={styles.number}>
                {tc('organization-opportunity-card.remaining-applicants', {
                  count: remainingApplicantsCount,
                })}
              </p>
            </div>
          ) : null}
        </div>
        <p className={styles.description}>
          {tc('organization-opportunity-card.registered-students', {
            count: application_count,
          })}
        </p>
      </div>
    );
  }
);

EventApplicants.displayName = 'EventApplicants';
