import React, { memo } from 'react';
import cn from 'classnames';

import Chip from 'components/Chip';
import { formatToK, getCurrency } from 'utils/format';
import styles from './Salary.module.scss';

export interface SalaryProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  maximum_salary?: number | null;
  minimum_salary?: number | null;
  pay_currency?: string;
}

export const Salary: React.FC<SalaryProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    maximum_salary,
    minimum_salary,
    pay_currency,
  }: SalaryProps) => {
    const contentClassNames = cn(styles.Salary, className);

    return (
      <Chip className={contentClassNames} style={style} data-testid="Salary">
        {minimum_salary
          ? `${getCurrency(pay_currency || '')}${formatToK(minimum_salary)}`
          : null}
        {minimum_salary && maximum_salary ? ` - ` : null}
        {maximum_salary
          ? `${getCurrency(pay_currency || '')}${formatToK(maximum_salary)}`
          : null}
      </Chip>
    );
  }
);

Salary.displayName = 'Salary';
