import React, { memo, forwardRef } from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import { Spinner } from 'components';
import Icon, { IconName, IconSize } from 'components/Icon';

import styles from './Button.module.scss';

export type ButtonVariant = 'contained' | 'outlined' | 'text';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** How large should the button be? */
  iconSize?: IconSize;
  /** size of the button */
  size?: 'small' | 'medium' | 'large';
  /** What should be the button's variant */
  variant?: ButtonVariant;
  /** color of the button */
  color?:
    | 'primary'
    | 'primary-light'
    | 'primary-faint'
    | 'secondary'
    | 'secondary-faint'
    | 'danger'
    | 'white'
    | 'black'
    | 'dark-grey'
    | 'blue';
  /** Is button full width? */
  isFullWidth?: boolean;
  /** start icon name */
  startIcon?: IconName;
  /** end icon name */
  endIcon?: IconName;
  /** borderless? */
  borderless?: boolean;
  /** isLoading */
  isLoading?: boolean;
  showOnlySpinner?: boolean;
}

export const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        className = '', // custom class name
        style, // custom style
        iconSize,
        size = 'large',
        color = 'primary',
        variant = 'contained',
        borderless = false,
        isFullWidth = false,
        startIcon,
        endIcon,
        isLoading = false,
        showOnlySpinner = false,
        children,
        ...props
      },
      ref
    ) => {
      const colorName = color.split('-').map(capitalize).join('');
      const contentClassNames = cn(className, {
        [styles.Button]: true,
        [`${styles[`ButtonColor${colorName}`]}`]: true,
        [`${styles[`ButtonVariant${capitalize(variant)}`]}`]: true,
        [`${styles[`ButtonSize${capitalize(size)}`]}`]: true,
        [styles.ButtonIconLeft]: startIcon,
        [styles.ButtonIconRight]: endIcon,
        [styles.ButtonFullWidth]: isFullWidth,
        [styles.ButtonBorderless]: borderless,
        [styles.ButtonLoading]: isLoading,
      });

      const loaderColor =
        color === 'secondary' || color === 'white' ? 'primary' : 'secondary';

      return (
        <button
          className={contentClassNames}
          style={style}
          data-testid="Button"
          ref={ref}
          {...props}
        >
          {showOnlySpinner && isLoading ? null : (
            <>
              {startIcon ? (
                <Icon
                  iconName={startIcon}
                  size={iconSize}
                  className={styles.ButtonIcon}
                />
              ) : null}
              {children}{' '}
              {endIcon ? (
                <Icon
                  iconName={endIcon}
                  size={iconSize}
                  className={styles.ButtonIcon}
                />
              ) : null}
            </>
          )}
          {isLoading && (
            <Spinner
              className={cn(styles.ButtonSpinner, {
                [styles.ButtonSpinnerOnly]: showOnlySpinner,
              })}
              size="xxsmall"
              color={loaderColor}
            />
          )}
        </button>
      );
    }
  )
);

Button.displayName = 'Button';
