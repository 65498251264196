import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  UseAuthReturnType,
  useAuthInternalHook,
} from 'hooks/useAuth/useAuthInternal';

const initialContext: UseAuthReturnType = {
  isInitialAuthLoading: false,
  setIsAuthLoading: noop,
  isAuthLoading: false,
  user: null,
  candidate: null,
  manager: null,
  recruiter: null,
  recruiterCompany: null,
  candidateUniversity: null,
  isLoggedIn: false,
  isManager: false,
  isRecruiter: false,
  isCandidate: false,
  isTemporaryCandidate: false,
  isUnapprovedManager: false,
  isUnapprovedRecruiter: false,
  userName: '',
  isNewCandidate: false,
  setIsNewCandidate: noop,
  managerSociety: null,
  basicParticipant: null,
  isNonOnboardedUser: false,
  isGuestCandidate: false,
  updateUserApiResponse: noop,
  updatePartialManager: noop,
  onUserLogout: noop,
};

const AuthContext = createContext<UseAuthReturnType>(initialContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const value = useAuthInternalHook();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
