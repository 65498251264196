import React, { memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import Icon from 'components/Icon';
import { useResponsive } from 'hooks/useResponsive';

import styles from './Tooltip.module.scss';

export interface TooltipProps {
  childrenClassName?: string;
  containerClassName?: string;
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** position of tooltip */
  position?: 'top' | 'bottom' | 'left' | 'right';
  /** horizontal alignment */
  xAlign?: 'left' | 'center' | 'right';
  /** vertical alignment */
  yAlign?: 'top' | 'middle' | 'bottom';
  /** auto horizontal alignment if it goes outside viewport */
  xAutoAlign?: boolean;
  /** tooltip content */
  content: React.ReactNode;
  /** color */
  color?: 'primary' | 'dark';
  /** children */
  children: React.ReactNode;
  disabled?: boolean;
  isV2Design?: boolean;
  hasWhitespaceNoWrap?: boolean;
  isRounded?: boolean;
  contentPositionClassName?: string;
  showTippy?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = memo(
  ({
    childrenClassName,
    containerClassName,
    className = '', // custom class name
    style, // custom style
    position = 'bottom',
    xAlign = position === 'top' || position === 'bottom' ? 'center' : undefined,
    yAlign = position === 'left' || position === 'right' ? 'middle' : undefined,
    xAutoAlign = false,
    children,
    color = 'primary',
    content,
    disabled,
    isV2Design,
    hasWhitespaceNoWrap = true,
    isRounded = false,
    contentPositionClassName,
    showTippy = false,
  }: TooltipProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const positionRef = useRef<HTMLDivElement>(null);

    const [isOpen, setOpen] = useState(false);
    const timeoutRef = useRef<number | null>(null);
    const screens = useResponsive();

    useEffect(() => {
      const windowWidth =
        window.innerWidth > window.outerWidth
          ? window.outerWidth
          : window.innerWidth;
      if (
        !xAutoAlign ||
        !contentRef.current ||
        !positionRef.current ||
        (contentRef.current.getBoundingClientRect().right < windowWidth &&
          contentRef.current.getBoundingClientRect().left > 0)
      )
        return;

      const overflowLeft = contentRef.current.getBoundingClientRect().left;
      const overflowRight =
        contentRef.current.getBoundingClientRect().right - windowWidth;

      if (overflowLeft < 0) {
        positionRef.current.style.transform = `translateX(calc(${
          xAlign === 'center'
            ? '-50% + ' + (-overflowLeft + 5)
            : -overflowLeft + 5
        }px))`;
      }
      if (overflowRight > 0) {
        positionRef.current.style.transform = `translateX(calc(${
          xAlign === 'center'
            ? '-50% - ' + (overflowRight + 20)
            : -overflowRight - 20
        }px))`;
      }
    }, [isOpen]);

    const contentPositionClassNames = cn(
      styles.TooltipContentPosition,
      contentPositionClassName,
      {
        [`${styles[`TooltipContentPosition${capitalize(position)}`]}`]:
          position,
        [`${styles[`TooltipContentHorizontal${capitalize(xAlign)}`]}`]: xAlign,
        [`${styles[`TooltipContentVertical${capitalize(yAlign)}`]}`]: yAlign,
      }
    );

    const contentClassNames = cn(
      {
        [styles.TooltipContent]: true,
        [styles.TooltipContentRounded]: isRounded,
        [`${styles[`TooltipContentColor${capitalize(color)}`]}`]: color,
        [styles.v2Design]: isV2Design,
        [styles['whitespace-nowrap']]: hasWhitespaceNoWrap,
      },
      className
    );

    const arrowIcon =
      position === 'top' || position === 'bottom'
        ? 'icon_tippy_x'
        : 'icon_tippy_y';

    useEffect(() => {
      if (isOpen && screens.sm) {
        timeoutRef.current = window.setTimeout(() => {
          setOpen(false);
        }, 3000);
      }
      return () => {
        if (timeoutRef.current) {
          window.clearTimeout(timeoutRef.current);
        }
      };
    }, [isOpen]);

    return (
      <section
        className={cn(styles.Tooltip, containerClassName)}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <div onClick={() => setOpen(true)} className={childrenClassName}>
          {children}
        </div>
        {isOpen && !disabled && (
          <div
            className={contentPositionClassNames}
            data-testid="Tooltip"
            ref={positionRef}
          >
            <div className={contentClassNames} style={style} ref={contentRef}>
              {showTippy && (
                <div className={styles['arrow-container']}>
                  <Icon
                    className={styles.arrow}
                    iconName={arrowIcon}
                    size="auto"
                  />
                </div>
              )}
              {content}
            </div>
          </div>
        )}
      </section>
    );
  }
);

Tooltip.displayName = 'Tooltip';
