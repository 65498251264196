import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import { UseAppReturnType, useApp as useAppInternal } from 'hooks/useApp';

const initialContext: UseAppReturnType = {
  setCallback: noop,
  getCallback: () => null,
  routesVisited: [],
  email: null,
  setEmail: noop,
  societySearch: null,
  setSocietySearch: noop,
  shouldHideMobileFooter: false,
  setHideMobileFooter: noop,
  locationList: [],
  followable_id: null,
  setFollowableId: noop,
  handleRemoveRoute: () => true,
  writeApplicationModalType: null,
  setWriteApplicationModalType: noop,
  previousRoute: null,
  setPreviousRoute: noop,
  recruiterMessage: null,
  setRecruiterMessage: noop,
  setRoomData: noop,
  roomData: null,
  isPublicPage: false,
  isCandidateRoute: false,
  headerVisibleSmall: false,
  footerVisibleSmall: false,
  isHeaderVisible: false,
  isFooterVisible: false,
  handleAutofillApplicationsClick: () => null,
  aiSearches: [],
  isAISearchLoading: false,
  mutateAISearchesResponse: () => Promise.resolve(undefined),
};

const SaveInAppContext = createContext<UseAppReturnType>(initialContext);

export const useApp = (): UseAppReturnType => useContext(SaveInAppContext);

export const SaveInAppProvider: React.FC = ({ children }) => {
  const value = useAppInternal();

  return (
    <SaveInAppContext.Provider value={value}>
      {children}
    </SaveInAppContext.Provider>
  );
};
