import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useNotification } from 'hooks/useNotification';
import { ToolUserAttributes, UserResponse, UserRole } from 'lib/models/user';
import { CandidateResponse, CandidateUserAttributes } from 'lib/models/candidate';
import { API_ROUTES } from 'lib/api-routes';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { userSecretState } from 'lib/atoms/userSecretAtom';
import { TManager, TUser, TUserApiAttributes, TUserApiResponse, TUserType } from 'lib/models/User.model';
import { apiInstance, setAuthHeaders } from 'lib/utils/axios';
import { getUserAvatarFields, isCandidateUserable, isManagerUserable, isRecruiterUserable } from 'utils/user';

export const useAuthInternalHook = () => {
  const [isNewCandidate, setIsNewCandidate] = useState<boolean>(false);
  const [userApiResponse, setUserApiResponse] = useState<TUserApiResponse | null>(null);
  const { replace, query } = useRouter();
  const [userSecret, setUserSecret] = useRecoilState(userSecretState);
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);
  const [isInitialAuthLoading, setInitialAuthLoading] = useState<boolean>(true);
  const notificationInstance = useNotification();
  const [tc] = useTranslation('common');

  const updateUserUsingStorage = useCallback(async () => {
    try {
      setIsAuthLoading(true);
      setInitialAuthLoading(true);

      const { data: userApiResponse } = await apiInstance.get<TUserApiResponse>(API_ROUTES.USER_INFO);
      updateUserApiResponse(userApiResponse);
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 401) {
        setUserSecret(null);
      }
    } finally {
      setIsAuthLoading(false);
      setInitialAuthLoading(false);
    }
  }, []);

  const updateUserUsingQueryParam = useCallback(async ({ candidate_id, token }: { candidate_id: string; token: string }) => {
    try {
      setIsAuthLoading(true);
      setInitialAuthLoading(true);
      const route = `${API_ROUTES.CANDIDATE}?candidate_id=${candidate_id}&magic_token=${token}`;
      const { data: userApiResponse } = await apiInstance.get<TUserApiResponse>(route);

      const urlParams = new URLSearchParams(window.location.search);
      const action = urlParams.get('action');
      const { candidate_id: _, token: __, ...rest } = query;
      updateUserApiResponse(userApiResponse);
      replace({ query: { action, ...rest } }, undefined, { shallow: true });
    } catch (error) {
      console.error(error);
    } finally {
      setIsAuthLoading(false);
      setInitialAuthLoading(false);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const candidate_id = urlParams.get('candidate_id'),
      token = urlParams.get('token');

    if (candidate_id && token) {
      updateUserUsingQueryParam({ candidate_id, token });
    } else if (userSecret?.email && userSecret?.token) {
      updateUserUsingStorage();
    } else {
      setIsAuthLoading(false);
      setInitialAuthLoading(false);
    }
  }, []);

  const setUserSession = useCallback(
    (user: TUser) => {
      if (user && !(userSecret?.email === user.email && userSecret?.token === user.authentication_token)) {
        setUserSecret({ email: user.email, token: user.authentication_token });
      }
    },
    [userSecret]
  );

  const updateUserApiResponse = useCallback(
    (userApiResponse?: TUserApiResponse) => {
      try {
        if (userApiResponse) {
          const { user: userResponse } = parseResponse(userApiResponse);
          if (userApiResponse.data.type === TUserType.ADMIN) {
            notificationInstance.error({
              title: tc('unauthorized-access.admin.title'),
              message: tc('unauthorized-access.admin.message'),
            });
            return;
          }

          const user = parseResponse(userResponse);
          setUserSession(user);
          setUserApiResponse(userApiResponse);
        } else {
          updateUserUsingStorage();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [setUserSession, updateUserUsingStorage]
  );

  const updatePartialManager = useCallback(
    (partialManager: Partial<TManager>) => {
      setUserApiResponse((oldUserApiResponse) => {
        if (!oldUserApiResponse) return oldUserApiResponse;

        const userAttributes = parseResponse<TUserApiAttributes>(oldUserApiResponse);
        if (isManagerUserable(userAttributes)) {
          const updatedUserAttributes = { ...userAttributes, ...partialManager };
          return {
            ...oldUserApiResponse,
            data: {
              ...oldUserApiResponse.data,
              attributes: updatedUserAttributes,
            },
          };
        } else {
          return oldUserApiResponse;
        }
      });
    },
    [updateUserApiResponse]
  );

  const { user, candidate, manager, managerSociety, recruiter, recruiterCompany, candidateUniversity } = useMemo(() => {
    if (!userApiResponse)
      return {
        user: null,
        candidate: null,
        candidateUniversity: null,
        manager: null,
        managerSociety: null,
        recruiter: null,
        recruiterCompany: null,
      };

    const userAttributes = parseResponse<TUserApiAttributes>(userApiResponse);
    const user = parseResponse(userAttributes.user);
    const candidate = isCandidateUserable(userAttributes) ? userAttributes : null;
    const manager = isManagerUserable(userAttributes) ? userAttributes : null;
    const managerSociety = manager?.student_society ? parseResponse(manager.student_society) : null;
    const recruiter = isRecruiterUserable(userAttributes) ? userAttributes : null;
    const recruiterCompany = recruiter?.company ? parseResponse(recruiter.company) : null;
    const candidateUniversityEducationalHistories = candidate
      ? parseArrayResponse(candidate.education_histories).filter((education_history) => education_history.education_type === 'university')
      : [];
    const candidateUniversity =
      candidateUniversityEducationalHistories.length > 0 ? parseResponse(candidateUniversityEducationalHistories[0].university) : null;
    return {
      user,
      candidate,
      candidateUniversity,
      manager,
      managerSociety,
      recruiter,
      recruiterCompany,
    };
  }, [userApiResponse]);

  const { isLoggedIn, isManager, isRecruiter, isCandidate, isTemporaryCandidate, isUnapprovedManager, isUnapprovedRecruiter } = useMemo(() => {
    let isLoggedIn = true,
      isCandidate = false,
      isManager = false,
      isRecruiter = false,
      isTemporaryCandidate = false,
      isUnapprovedManager = false,
      isUnapprovedRecruiter = false;

    if (manager) {
      if (manager.user.data.attributes.is_approved === false) {
        isUnapprovedManager = true;
      } else {
        isManager = true;
      }
    } else if (recruiter) {
      if (recruiter.user.data.attributes.is_approved === false) {
        isUnapprovedRecruiter = true;
      } else {
        isRecruiter = true;
      }
    } else if (candidate) {
      if (user?.email === '') isTemporaryCandidate = true;
      else isCandidate = true;
    } else {
      isLoggedIn = false;
    }
    return {
      isLoggedIn,
      isManager,
      isUnapprovedManager,
      isRecruiter,
      isUnapprovedRecruiter,
      isCandidate,
      isTemporaryCandidate,
    };
  }, [user, candidate, manager, managerSociety, recruiter, recruiterCompany]);

  const userName = useMemo(() => {
    let name = '';
    if (user) {
      const { first_name, last_name } = user;
      name = (first_name ? first_name : '') + ' ' + (last_name ? last_name : '');
    }
    return name;
  }, [user]);

  const basicParticipant = useMemo(() => {
    return getUserAvatarFields({
      candidate,
      society: managerSociety,
      company: recruiterCompany,
    });
  }, [candidate, managerSociety, recruiterCompany]);

  const isNonOnboardedUser = useMemo(() => {
    if ((isAuthLoading || isInitialAuthLoading) && !isCandidate) return false;
    return candidate?.onboarding_completed === false;
  }, [isCandidate, candidate, isAuthLoading, isInitialAuthLoading]);

  const onUserLogout = useCallback(() => {
    setUserSecret(null);
    setUserApiResponse(null);
    setAuthHeaders(null);
  }, []);

  const isGuestCandidate = !isCandidate && !isInitialAuthLoading;

  return {
    isAuthLoading,
    setIsAuthLoading,
    isInitialAuthLoading,
    user,
    manager,
    managerSociety,
    recruiter,
    recruiterCompany,
    userName,
    isLoggedIn,
    isManager,
    isUnapprovedManager,
    isRecruiter,
    isUnapprovedRecruiter,
    isCandidate,
    isTemporaryCandidate,
    isNewCandidate,
    candidate,
    candidateUniversity,
    basicParticipant,
    isNonOnboardedUser,
    isGuestCandidate,
    setIsNewCandidate,
    updateUserApiResponse,
    updatePartialManager,
    onUserLogout,
  };
};

export const isToolUser = (user: ToolUserAttributes | CandidateUserAttributes | null): user is ToolUserAttributes => {
  const role = user?.userable_type.toLowerCase();
  return role === UserRole.MANAGER || role === UserRole.RECRUITER;
};

export const isCandidateUser = (user: ToolUserAttributes | CandidateUserAttributes | null): user is CandidateUserAttributes => {
  const role = user?.userable_type.toLowerCase();
  return role === UserRole.CANDIDATE;
};

export const isCandidateType = (user: UserResponse | CandidateResponse): user is CandidateResponse => {
  return user?.data?.type === UserRole.CANDIDATE;
};

export type UseAuthReturnType = ReturnType<typeof useAuthInternalHook>;
