import React, { useContext, createContext } from 'react';

import { noop } from 'lodash';
import { SocietyGlobalReturnType, useSociety } from 'hooks/useSociety';

const initialContext: SocietyGlobalReturnType = {
  selectedTouchpoints: [],
  setSelectedTouchpoints: noop,
  getCopiedLink: async () => undefined,
};

const SocietyGlobalContext =
  createContext<SocietyGlobalReturnType>(initialContext);

export const useSocietyGlobal = (): SocietyGlobalReturnType =>
  useContext(SocietyGlobalContext);

export const SocietyGlobalContextProvider: React.FC = ({ children }) => {
  const value = useSociety();

  return (
    <SocietyGlobalContext.Provider value={value}>
      {children}
    </SocietyGlobalContext.Provider>
  );
};
