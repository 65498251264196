import { memo } from 'react';
import cx from 'classnames';

import styles from './Stat.module.scss';

type StatProps = {
  className?: string;
  value: string | number;
  label: string;
};

export const Stat: React.FC<StatProps> = memo(
  ({ className, value, label }: StatProps) => {
    return (
      <div className={cx(styles.container, className)}>
        <div className={styles.value}>{value}</div>
        <div className={styles.label}>{label}</div>
      </div>
    );
  }
);

Stat.displayName = 'Stat';
