import React, { memo } from 'react';
import cn from 'classnames';
import { RegisterOptions, UseFormRegister, FieldValues } from 'react-hook-form';

import Selection from 'components/form/Selection';
import { Option } from 'lib/models/option';

import styles from './TabRadioGroup.module.scss';

export interface TabRadioGroupProps {
  className?: string;
  name: string;
  options: Array<Option>;
  hasBorder?: boolean;
  register: UseFormRegister<FieldValues>;
  registerOptions?: RegisterOptions;
}

export const TabRadioGroup: React.FC<TabRadioGroupProps> = memo(
  ({
    className = '',
    name,
    hasBorder = false,
    register,
    registerOptions,
    options = [],
  }) => {
    const contentClassNames = cn(styles.container, className, {
      [styles.hasBorder]: hasBorder,
    });

    if (options.length === 0) return null;

    return (
      <fieldset className={contentClassNames} data-testid="TabRadioGroup">
        {options.map((option) => (
          <Selection
            key={option.id}
            type="radio"
            value={option.value}
            className={styles.item}
            {...register(name, registerOptions)}
          >
            {option.label}
          </Selection>
        ))}
      </fieldset>
    );
  }
);

TabRadioGroup.displayName = 'TabRadioGroup';
