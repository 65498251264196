import React, { memo } from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import styles from './Chip.module.scss';

export interface ChipProps {
  /** Custom class name */
  className?: string;
  /** children node */
  children: React.ReactNode;
  /** variant of chip */
  color?: 'primary' | 'secondary' | 'tertiary';
  /** Custom style */
  style?: Record<string, unknown>;
}

export const Chip: React.FC<ChipProps> = memo(
  ({
    className = '', // custom class name
    children,
    style, // custom style
    color = 'secondary',
  }: ChipProps) => {
    const contentClassNames = cn(
      {
        [styles.Chip]: true,
        [`${styles[`ChipColor${capitalize(color)}`]}`]: true,
      },
      className
    );

    return (
      <div className={contentClassNames} style={style} data-testid="Chip">
        {children}
      </div>
    );
  }
);

Chip.displayName = 'Chip';
