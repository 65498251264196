import React, { memo } from 'react';
import cn from 'classnames';

import Selection from 'components/form/Selection';
import { Option } from 'lib/models/option';

import styles from './TabRadioGroupV2.module.scss';

export interface TabRadioGroupV2Props {
  className?: string;
  optionClassName?: string;
  title?: string;
  value: string;
  options: Array<Option>;
  hasBorder?: boolean;
  onChange: (value: string) => void;
}

export const TabRadioGroupV2: React.FC<TabRadioGroupV2Props> = memo(
  ({
    className = '',
    title,
    value,
    hasBorder = false,
    options = [],
    onChange,
    optionClassName = '',
  }) => {
    const contentClassNames = cn(styles.container, className, {
      [styles.hasBorder]: hasBorder,
    });

    const optionClasses = cn({ [styles.item]: true, [optionClassName]: true });

    if (options.length === 0) return null;

    return (
      <div className={styles.TabRadioGroupV2}>
        {title ? <div className={styles.title}>{title}</div> : null}
        <fieldset className={contentClassNames} data-testid="TabRadioGroupV2">
          {options.map((option) => (
            <Selection
              key={option.id}
              type="radio"
              checked={option.value === value}
              className={optionClasses}
              onChange={() => onChange(option.value)}
            >
              {option.label}
            </Selection>
          ))}
        </fieldset>
      </div>
    );
  }
);

TabRadioGroupV2.displayName = 'TabRadioGroupV2';
