import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

import { useAuth } from 'lib/providers/AuthProvider';
import { PAGE_ROUTES } from 'lib/page-routes';

export const useIntercom = (): void => {
  const router = useRouter();
  const { pathname } = router;
  const { user, managerSociety, recruiterCompany } = useAuth();
  const { isManager, isRecruiter } = useAuth();

  const settings = useMemo(() => {
    let setting;
    if (user) {
      const { first_name, last_name, email, created_at, userable_type } = user;
      const name = `${first_name ? first_name : ''} ${
        last_name ? last_name : ''
      }`;
      setting = {
        app_id: 'gq9eqp9p',
        email,
        first_name,
        last_name,
        name,
        created_at: +new Date(created_at),
        type: userable_type,
      };

      if (managerSociety || recruiterCompany) {
        const company = recruiterCompany || managerSociety;
        if (company) {
          setting = {
            ...setting,
            company: {
              id: company.id,
              name: company.name,
            },
          };
        }
      }
    } else {
      setting = {
        app_id: 'gq9eqp9p',
      };
    }
    return setting;
  }, [user, managerSociety, recruiterCompany]);

  useEffect(() => {
    // Intercom code snippet
    (function () {
      const w = window;
      // @ts-ignore
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        // @ts-ignore
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        // @ts-ignore
        const i = function (...args) {
          // @ts-ignore
          i.c(args);
        };
        // @ts-ignore
        i.q = [];
        // @ts-ignore
        i.c = function (args) {
          // @ts-ignore
          i.q.push(args);
        };
        // @ts-ignore
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/gq9eqp9p';
          const x = d.getElementsByTagName('script')[0];
          // @ts-ignore
          x.parentNode.insertBefore(s, x);
        };
        // @ts-ignore
        if (w.attachEvent) {
          // @ts-ignore
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();

    // Call boot method
    // @ts-ignore
    window?.Intercom('boot', settings);
  }, [settings]);

  useEffect(() => {
    if (isManager || isRecruiter) {
      if (pathname === PAGE_ROUTES.MESSAGES) {
        // @ts-ignore
        window?.Intercom('update', {
          hide_default_launcher: false,
          alignment: 'left',
          horizontal_padding: 20,
          vertical_padding: 20,
        });
      } else {
        // @ts-ignore
        window?.Intercom('update', {
          hide_default_launcher: false,
          alignment: 'right',
          horizontal_padding: 20,
          vertical_padding: 20,
        });
      }
    } else {
      // @ts-ignore
      window?.Intercom('update', {
        hide_default_launcher: true,
      });
    }
  }, [isManager, isRecruiter, pathname]);
};
