import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
} from 'react';
import cn from 'classnames';
import { Listbox } from '@headlessui/react';

import { Icon, IconButton, IconName } from 'components';
import { Button } from 'components/form';
import { IconSize } from 'components/Icon';
import SelectionTickMark from 'components/form/SelectionTickMark';
import { useResponsive } from 'hooks/useResponsive';
import { Option } from 'lib/models/option';

import styles from './DropdownV3.module.scss';

export type DropdownOption = Option & {
  iconName?: IconName;
  groupEnd?: boolean;
  isDelete?: boolean;
  isDefault?: boolean;
  industry_ids?: Array<string>;
};

export interface DropdownV3Props {
  className?: string;
  title?: string;
  label: string;
  options: Array<DropdownOption>;
  isSearchable?: boolean;
  searchPlaceholder?: string;
  disabled?: boolean;
  hideChevron?: boolean;
  value: string;
  onChange: (value: string) => void;
  selectedValueLabel: string;
  isValid: boolean;
  noResultsOption?: string;
  labelClassName?: string;
  showOptionalLabel?: boolean;
  triggerButtonClassName?: string;
  labelStyle?: Record<string, unknown>;
  isDefaultTriggerClass?: boolean;
  size?: 'small' | 'large';
  variant?: 'primary' | 'secondary' | 'tertiary';
  infoToolTip?: React.ReactNode;
  context?: string;
  contextIcon?: IconName;
  onContextButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  helperText?: string;
  dropdownClassName?: string;
  dropdownBtnClassName?: string;
  selectedValueClassName?: string;
  iconClassName?: string;
  startIcon?: IconName;
  startIconSize?: IconSize;
  startIconClassName?: string;
  displayShowAllButton?: boolean;
  onClickShowAll?: () => void;
  showSelectionTick?: boolean;
  showCheckMark?: boolean;
  scrollToDropdown?: () => void;
  startMenuFromBottom?: boolean;
}

export const DropdownV3 = forwardRef<HTMLButtonElement, DropdownV3Props>(
  (
    {
      className = '',
      options,
      title,
      label,
      value,
      onChange,
      isSearchable = false,
      searchPlaceholder,
      disabled,
      selectedValueLabel,
      selectedValueClassName,
      isValid,
      noResultsOption,
      labelClassName = '',
      showOptionalLabel,
      triggerButtonClassName = '',
      labelStyle,
      isDefaultTriggerClass = true,
      size = 'small',
      variant = 'primary',
      infoToolTip,
      context,
      contextIcon,
      onContextButtonClick,
      helperText = '',
      dropdownClassName = '',
      dropdownBtnClassName = '',
      iconClassName = '',
      startIcon,
      startIconSize,
      startIconClassName,
      displayShowAllButton = false,
      onClickShowAll,
      showSelectionTick = false,
      showCheckMark = true,
      scrollToDropdown,
      startMenuFromBottom = false,
      hideChevron = false,
    },
    ref
  ) => {
    const contentClassNames = cn(styles.DropdownV3Container, className, {
      [styles.disabled]: disabled,
      [styles.isInValid]: !isValid,
    });
    const [search, setSearch] = useState('');
    const dropdownButtonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const screens = useResponsive();
    const trimmedSearch = search.trim();

    const filteredOptions = useMemo(() => {
      const list = isSearchable
        ? options.filter(
            (option) =>
              option.label.toLowerCase().indexOf(trimmedSearch.toLowerCase()) >
              -1
          )
        : options;

      const selectedOption = options.find((option) => option.value === value);

      if (list.length > 0) {
        if (selectedOption) {
          const listWithoutSelected = list.filter(
            (option) => option.value !== value
          );
          return [selectedOption, ...listWithoutSelected];
        }
        return list;
      }

      if (noResultsOption) {
        const option = options.find(
          (option) =>
            option.label.toLowerCase() === noResultsOption.toLowerCase()
        );
        if (option) return [option];
        else return list;
      } else {
        return list;
      }
    }, [trimmedSearch, options, isSearchable, noResultsOption, value]);

    const handleChange = useCallback(
      (value) => {
        setSearch('');
        onChange(value);
      },
      [onChange]
    );

    const onSearchKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      event.stopPropagation();
    };

    const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
      event
    ) => {
      setSearch(event.target.value);
    };

    const focusInput = useCallback((ref) => {
      setTimeout(() => {
        if (ref) ref.focus();
      }, 200);
    }, []);

    useEffect(() => {
      const handleScroll = () => {
        screens.sm && updateDropdownMaxHeight();
      };
      window?.addEventListener('scroll-top', handleScroll);

      return () => {
        window?.removeEventListener('scroll-top', handleScroll);
      };
    }, [screens.sm]);

    const updateDropdownMaxHeight = () => {
      if (dropdownRef.current && screens.sm) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const maxHeight = window.innerHeight - dropdownRect.top - 12;
        dropdownRef.current.style.maxHeight = `${maxHeight}px`;
        dropdownRef.current.style.overflow = 'auto';
      }
    };

    useEffect(() => {
      if (screens.sm && isDropdownOpen) {
        scrollToDropdown && scrollToDropdown();
      }
    }, [screens.sm, isDropdownOpen]);

    useEffect(() => {
      const button = dropdownButtonRef.current;

      if (button) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (
              mutation.type === 'attributes' &&
              mutation.attributeName === 'aria-expanded'
            ) {
              const expanded = button.getAttribute('aria-expanded') === 'true';
              setIsDropdownOpen(expanded);
            }
          });
        });

        observer.observe(button, { attributes: true });

        return () => observer.disconnect();
      }
    }, []);

    return (
      <Listbox
        as="div"
        ref={ref}
        className={contentClassNames}
        value={value}
        onChange={handleChange}
        tabIndex={-1}
      >
        {title && (
          <label
            className={cn(styles.label, labelClassName)}
            style={labelStyle}
          >
            <div>
              {title}
              {showOptionalLabel ? (
                <span className={styles.optional}> (optional)</span>
              ) : null}
              {infoToolTip && (
                <span className={styles.infoToolTip}>{infoToolTip}</span>
              )}
            </div>
            <div>
              {!isValid && helperText ? (
                <label className={styles.helpertext}>{helperText}</label>
              ) : null}
            </div>
          </label>
        )}
        <Listbox.Button
          className={cn(dropdownBtnClassName, {
            [styles.trigger]: isDefaultTriggerClass,
            [triggerButtonClassName]: true,
            [styles.small]: size === 'small',
            [styles.large]: size === 'large',
            [styles.primary]: variant === 'primary',
            [styles.secondary]: variant === 'secondary',
            [styles.tertiary]: variant === 'tertiary',
          })}
          ref={dropdownButtonRef}
          type="button"
          aria-disabled={disabled}
        >
          {startIcon && (
            <Icon
              iconName={startIcon}
              size={startIconSize}
              className={cn(
                styles.ButtonV3IconLeft,
                styles.ButtonV3Icon,
                startIconClassName
              )}
            />
          )}
          {selectedValueLabel ? (
            <span className={cn(styles.selectedValue, selectedValueClassName)}>
              {selectedValueLabel}
            </span>
          ) : (
            <span className={styles.buttonLabel}>{label}</span>
          )}
          {!disabled && !hideChevron && (
            <Icon
              iconName="icon_arrow-down"
              className={cn(styles.chevron, iconClassName)}
              size="xsmall"
            />
          )}
        </Listbox.Button>
        {context ? (
          <div className={styles.contextContainer}>
            {contextIcon ? (
              <IconButton
                type="button"
                iconName={contextIcon}
                size="auto"
                onClick={onContextButtonClick}
                iconClassName={styles.contextIcon}
              />
            ) : null}
            <p className={styles.context}>{context}</p>
          </div>
        ) : null}
        <Listbox.Options
          className={cn(styles.dropdown, dropdownClassName, {
            [styles['from-bottom']]: startMenuFromBottom,
          })}
        >
          {isSearchable ? (
            <div className={styles.search}>
              <input
                ref={focusInput}
                value={search}
                placeholder={searchPlaceholder}
                onKeyDown={onSearchKeyDown}
                onChange={onSearchChange}
              />
            </div>
          ) : null}
          <div className={styles.options} ref={dropdownRef}>
            {filteredOptions.map((option) => {
              const { id, value, label, iconName, groupEnd, isDelete } = option;
              return (
                <Listbox.Option
                  key={id}
                  value={value}
                  className={cn(styles.option, {
                    [styles.groupEnd]: groupEnd,
                    [styles.optionIcon]: iconName,
                  })}
                >
                  {iconName && (
                    <Icon iconName={iconName} className={styles.listIcon} />
                  )}
                  {showSelectionTick ? (
                    <>
                      <SelectionTickMark
                        checked={label === selectedValueLabel}
                        className={styles.selectionTickmark}
                        showSelectionCircle={true}
                      >
                        <span
                          className={cn({ [styles.deleteLabel]: isDelete })}
                        >
                          {label}
                        </span>
                      </SelectionTickMark>
                    </>
                  ) : (
                    <>
                      <span className={cn({ [styles.deleteLabel]: isDelete })}>
                        {label}
                      </span>
                      {showCheckMark && (
                        <Icon
                          iconName="valid-check-mark"
                          className={styles.checkMark}
                        />
                      )}
                    </>
                  )}
                </Listbox.Option>
              );
            })}
            {displayShowAllButton && (
              <Button
                variant="text"
                startIcon="arrow-button-left"
                size="medium"
                iconSize="medium"
                className={styles.showAllBtn}
                onClick={onClickShowAll}
              >
                Show all
              </Button>
            )}
          </div>
        </Listbox.Options>
      </Listbox>
    );
  }
);

DropdownV3.displayName = 'DropdownV3';
