import React, { memo, forwardRef } from 'react';
import cn from 'classnames';

import { IconButton } from 'components';
import Icon, { IconName, IconSize } from 'components/Icon';

import styles from './Input.module.scss';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Custom style for input element */
  inputStyle?: Record<string, unknown>;
  /** input class name */
  inputClassName?: string;
  /** What is the label for the input element? */
  label?: string;
  /** Is input field valid? */
  isValid?: boolean;
  /** What is the error message? */
  helperText?: string;
  /** start icon name */
  startIcon?: IconName;
  /** start icon className */
  startIconClassName?: string;
  /** event handler on endIcon Click */
  onStartIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** end icon name */
  endIcon?: IconName | null;
  /** end icon classname */
  endIconClassName?: string;
  /** event handler on endIcon Click */
  onEndIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** size of the start icon */
  startIconSize?: IconSize;
  /** size of the end icon */
  endIconSize?: IconSize;
  /** className for label style */
  labelClassName?: string | null;
  showOptionalLabel?: boolean;
  infoToolTip?: React.ReactNode;
  showEndIconOnActive?: boolean;
}

export const Input = memo(
  forwardRef<HTMLInputElement | null, InputProps>(
    (
      {
        className = '',
        style,
        inputClassName,
        inputStyle,
        label = '',
        helperText = '',
        startIcon,
        endIcon,
        startIconSize = 'medium',
        endIconSize = 'medium',
        onStartIconClick,
        onEndIconClick,
        startIconClassName,
        endIconClassName,
        id,
        isValid = true,
        labelClassName = '',
        showOptionalLabel,
        infoToolTip,
        showEndIconOnActive = false,
        ...props
      },
      ref
    ) => {
      const contentClassNames = cn(
        {
          [styles.Input]: true,
          [styles.InputIsInvalid]: !isValid,
          [styles.InputHasIconLeft]: startIcon,
          [styles.InputHasIconRight]: endIcon,
        },
        className
      );

      const labelClassNames = cn(labelClassName, [styles.InputLabel]);

      const StartIconComponent = onStartIconClick ? IconButton : Icon;
      const EndIconComponent = onEndIconClick ? IconButton : Icon;

      return (
        <section
          className={contentClassNames}
          style={style}
          data-testid="InputContainer"
        >
          <div className={labelClassNames}>
            {label ? (
              <label className={labelClassNames} htmlFor={id}>
                <span></span>
                {label}
                {showOptionalLabel ? (
                  <span className={styles.optional}> (optional)</span>
                ) : null}
                {infoToolTip ? (
                  <span className={styles.infoToolTip}>{infoToolTip}</span>
                ) : null}
              </label>
            ) : null}
            {helperText ? (
              <label htmlFor={id} className={styles.InputHelperText}>
                {helperText}
              </label>
            ) : null}
          </div>
          <div className={styles.InputElementContainer}>
            {startIcon ? (
              <StartIconComponent
                iconName={startIcon}
                size={startIconSize}
                className={cn(
                  [styles.InputIcon],
                  [styles.InputIconLeft],
                  {
                    [styles.InputIconLeftButton]: onStartIconClick,
                  },
                  startIconClassName
                )}
                aria-labelledby={id}
                onClick={onStartIconClick}
                disabled={props.disabled}
                type="button"
              />
            ) : null}
            <input
              id={id}
              ref={ref}
              data-testid="Input"
              aria-invalid={!isValid}
              style={inputStyle}
              className={cn(styles.InputElement, inputClassName, {
                [styles.hideEndIconController]: showEndIconOnActive,
              })}
              {...props}
            />
            {endIcon ? (
              <EndIconComponent
                iconName={endIcon}
                size={endIconSize}
                className={cn(
                  [styles.InputIcon],
                  [styles.InputIconRight],
                  {
                    [styles.InputIconRightButton]: onEndIconClick,
                    [styles.hideEndIcon]: showEndIconOnActive,
                  },
                  endIconClassName
                )}
                aria-labelledby={id}
                onClick={onEndIconClick}
                disabled={props.disabled}
                type="button"
              />
            ) : null}
          </div>
        </section>
      );
    }
  )
);

Input.displayName = 'Input';
