import React, { forwardRef, memo } from 'react';
import cx from 'classnames';

import checkboxStyles from '../Checkbox/Checkbox.module.scss';
import styles from './CheckboxButton.module.scss';

interface CheckboxButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  children?: React.ReactNode;
}

const CheckboxButton = memo(
  forwardRef<HTMLInputElement, CheckboxButtonProps>(
    ({ className = '', children, ...props }, ref) => {
      return (
        <label
          className={cx(
            styles.CheckboxButton,
            checkboxStyles.Checkbox,
            className
          )}
        >
          {children}
          <input
            ref={ref}
            type="checkbox"
            data-testid="CheckboxButton"
            {...props}
          />
        </label>
      );
    }
  )
);

CheckboxButton.displayName = 'CheckboxButton';

export default CheckboxButton;
