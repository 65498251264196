import cx from 'classnames';

import styles from './FreeText.module.scss';

type FreeTextProps = {
  className?: string;
  content: string | string[];
};

const FreeText: React.FC<FreeTextProps> = ({ className, content }) => {
  if (!content) {
    return null;
  }

  return (
    <div className={cx(styles.container, className)}>
      {typeof content === 'string' ? (
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className={styles.content}
        />
      ) : (
        <ul>
          {content.map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </ul>
      )}
    </div>
  );
};

FreeText.displayName = 'FreeText';

export default FreeText;
