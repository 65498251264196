import React, { memo } from 'react';

export type FormProps = React.FormHTMLAttributes<HTMLFormElement>;

export const Form: React.FC<FormProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    children,
    ...props
  }: FormProps) => {
    return (
      <form
        className={className}
        style={style}
        data-testid="Form"
        noValidate
        {...props}
      >
        {children}
      </form>
    );
  }
);

Form.displayName = 'Form';
