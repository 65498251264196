import { UserAvatarFields, TUserableType } from 'lib/models/User.model';
import {
  TMediaPost,
  TPost,
  TPostType,
  TDocumentPost,
  TBasicStudentSociety,
} from 'lib/models/discussion';

export const isImageLandscape = (imageRef: HTMLImageElement | null) => {
  if (!imageRef) return null;
  const { width, height } = imageRef;
  if (!width || !height) return null;
  return width > height;
};

export function getDocumentExtension(filename: string) {
  const extension = filename.split('.').pop();
  switch (extension) {
    case 'docx':
      return 'doc';
    case 'xlsx':
      return 'xls';
    case 'pptx':
      return 'ppt';
    default:
      return extension;
  }
}

export function findURLs(text: string) {
  const urlPattern =
    /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  const urls = text.match(urlPattern);
  return urls;
}

export function getSocietyDiscussionsSettings(
  studentSociety: TBasicStudentSociety
) {
  const {
    who_can_post,
    who_can_reply_to_posts,
    show_media_uploads_from_posts,
    is_post_approval_required,
  } = studentSociety;
  return {
    who_can_post,
    who_can_reply_to_posts,
    show_media_uploads_from_posts,
    is_post_approval_required,
  };
}

export function getDiscussionMediaList(posts: Array<TPost>) {
  const mediaImages: TMediaPost['media'] = [];
  return posts.reduce((images, post) => {
    const { post_type, comments } = post;
    if (post_type === TPostType.MediaPost) {
      images.push(...(post.media || []));
    }
    if (comments.length > 0) {
      images.push(...getDiscussionMediaList(comments));
    }
    return images;
  }, mediaImages);
}

export function getDiscussionDocsList(posts: Array<TPost>) {
  const docs: TDocumentPost['documents'] = [];
  return posts.reduce((docs, post) => {
    const { post_type, comments } = post;
    if (post_type === TPostType.DocumentPost) {
      docs.push(...post.documents);
    }
    if (comments.length > 0) {
      docs.push(...getDiscussionDocsList(comments));
    }
    return docs;
  }, docs);
}

export async function downloadFile({
  url,
  filename,
}: {
  url: string;
  filename: string;
}) {
  try {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.target = '_blank';
    link.click();
  } catch (error) {
    console.error('Error downloading file', error);
  }
}

export function getBasicPostParticipant(post: TPost) {
  const { user, posted_by_user_student_society, posted_by_user_company } = post;
  if (posted_by_user_student_society) {
    return {
      id: posted_by_user_student_society.id,
      team_member_id: user.id,
      userable_type: TUserableType.MANAGER,
      name: posted_by_user_student_society.name,
      image_url: posted_by_user_student_society.icon_url,
      is_verified: posted_by_user_student_society.is_verified,
      is_huzzle_verified: posted_by_user_student_society.is_huzzle_verified,
    } as UserAvatarFields;
  } else if (posted_by_user_company) {
    return {
      id: posted_by_user_company.id,
      team_member_id: user.id,
      userable_type: TUserableType.RECRUITER,
      name: posted_by_user_company.name,
      image_url: posted_by_user_company.icon_url,
      is_verified: posted_by_user_company.is_verified,
      is_huzzle_verified: posted_by_user_company.is_huzzle_verified,
    } as UserAvatarFields;
  } else {
    const { user: userInfo } = post;
    return {
      id: userInfo.id,
      team_member_id: null,
      name: userInfo.name,
      userable_type: TUserableType.CANDIDATE,
      image_url: userInfo.avatar_url,
      is_verified: false,
      is_huzzle_verified: userInfo.is_huzzle_verified,
    } as UserAvatarFields;
  }
}

export const getBasicSocietyParticipant = (society: TBasicStudentSociety) => ({
  id: society.id,
  team_member_id: null,
  name: society.name,
  userable_type: TUserableType.MANAGER,
  image_url: society.icon_url || '',
  is_verified: society.is_verified,
  is_huzzle_verified: society.is_huzzle_verified,
});
