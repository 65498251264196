import { ReactElement } from 'react';
import { IconName } from 'components';

export enum NotificationTypeEnum {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Custom = 'custom',
  PendingCard = 'pending_invite_card',
  Acknowledge = 'acknowledge',
  Information = 'information',
  Failure = 'failure',
  Clear = 'clear',
}

export enum VerticalPositionEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum HorizontalPositionEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface Position {
  vertical: VerticalPositionEnum;
  horizontal: HorizontalPositionEnum;
}

export interface Notification {
  type: NotificationTypeEnum;
  message: string | ReactElement;
  loading?: boolean;
  timeout?: number;
  title?: string;
  position?: Position;
  renderContent?: (onClose?: () => void, type?: string) => React.ReactElement;
  icon?: IconName;
  showClose?: boolean;
  variant?: 'medium' | 'small' | 'micro';
  borderRounded?: boolean;
}
