import { useState, useEffect, useCallback } from 'react';
import ResponsiveObserve, { ScreenMap } from 'lib/utils/responsiveObserve';

type useResponsiveType = ScreenMap;

export const useResponsive = (): useResponsiveType => {
  const [screens, setScreens] = useState<ScreenMap>({
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
    xxl: true,
    sm_desktop_down: true,
    xs_up: true,
  });

  useEffect(() => {
    const token = ResponsiveObserve.subscribe((screen) => {
      setScreens(screen);
    });

    return () => ResponsiveObserve.unsubscribe(token);
  }, []);

  return screens;
};

export function useWindowDimensions() {
  const getWindowDimensions = useCallback(() => {
    const { innerWidth: viewportWidth, innerHeight: viewportHeight } = window;
    return {
      viewportWidth,
      viewportHeight,
    };
  }, []);

  const [windowDimensions, setWindowDimensions] = useState({
    viewportWidth: 0,
    viewportHeight: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
